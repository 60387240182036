import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { familyRegistrationReducer } from './pages/familyMemberRegistration/familyMemberRegistrationSlice';
import { loginPageReducer } from './pages/loginPage/loginPageSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { clientListReducer } from './pages/clientList/clientListSlice';
import { commonReducer } from './pages/common/commonSlice';
import { resetPasswordReducer } from './pages/resetPassword/resetPasswordSlice';
import { facilityListReducer } from './pages/facilityList/facilityListSlice';
import { associateListReducer } from './pages/associateUserList/associateListSlice';
import { providerListReducer } from './pages/providerList/providerListSlice';
import { nursingStaffReducer } from './pages/nursingStaffList/nursingStaffListSlice';
import { patientListReducer } from './pages/patientList/patientListSlice';
import { dashboardReducer } from './pages/dashboard/dashboardSlice';
import { patientRegistrationReducer } from './pages/patientRegistration/patientRegistrationSlice';
import { nursingStaffRegistrationReducer } from './pages/nursingStaffRegistration/nursingStaffRegSlice';
import { rolesListReducer } from './pages/rolesAndPermission/rolesAndPermissionSlice';
import { notificationListReducer } from './pages/notificationList/notificationSlice';
import { timeSheetReducer } from './pages/timeSheet/timeSheetSlice';
import { autoReplyListReducer } from './pages/autoReply/autoReplySlice';
import { messageTemplateDataReducer } from './pages/messageTemplate/messageTemplateSlice';

const persistConfig = {
  key: 'root',
  blacklist: ["familyRegistrationData","autoReplyListData", "clientListData", "resetPasswordData", "facilityListData", "associateListData", "commonData", "providerListData", "patientListData", "dashboardData", "patientRegistrationData", "nursingStaffRegistrationData", "nursingStaffData", "rolesListData", "notificationListData","timeSheetData","messageTemplateData"],
  storage,
}

const rootReducer = combineReducers({
  //Common Slice
  commonData: commonReducer,
  //Family registration
  familyRegistrationData: familyRegistrationReducer,
  //Patient registration
  patientRegistrationData: patientRegistrationReducer,
  //nursingStaff registration
  nursingStaffRegistrationData: nursingStaffRegistrationReducer,
  //Login page
  loginPageData: loginPageReducer,
  //Reset Password
  resetPasswordData: resetPasswordReducer,
  //Client List page
  clientListData: clientListReducer,
  //Facility List page
  facilityListData: facilityListReducer,
  //Associate List page
  associateListData: associateListReducer,
  //Provider List page
  providerListData: providerListReducer,
  //NursingStaff List page
  nursingStaffData: nursingStaffReducer,
  //Patient List page
  patientListData: patientListReducer,
  //Dashboard page
  dashboardData: dashboardReducer,
  //Roles and permissions
  rolesListData: rolesListReducer,
  //Notification list
  notificationListData: notificationListReducer,
  //Timesheet list
  timeSheetData: timeSheetReducer,
  //Auto Reply list
  autoReplyListData: autoReplyListReducer,
  //Message Template  list
  messageTemplateData: messageTemplateDataReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(thunk)
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import React, { useEffect, useState } from 'react'
import ButtonType from '../../components/button/ButtonType'
import plusIcon from '../../assets/images/plusIcon.svg'
import Table from '../../components/table'
import { clientListHeader } from '../../components/table/tableHeaders'
import { Box, Modal } from '@mui/material'
import TextField from '../../components/inputFeild/TextFeild'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import ImageInput from '../../components/inputFeild/ImageInput'
import SelectField from '../../components/inputFeild/SelectField'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { addClientData, addImageInput, addImageProfileInput, editClientData, fetchClientList, removeImageInput, removeImageProfileInput, resentVerificationClientListMail, updateClientData, updateStatus } from '../../services/clientListServices'
import { IClientData, IClientList } from '../../models/clientListInterfaces'
import { ClientListSlices } from './clientListSlice'
import { DEFAULT_COUNTRY, DEFAULT_COUNTRY_US, LOGO_UPLOAD_CLIENT, LOGO_UPLOAD_LOGO, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, STATUE_ACTIVE, USER_ROLE_SUPERADMIN } from '../../config/permissions'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import { filterDigitsOnly } from '../../helpers/validators'
import { CommonSlices } from '../common/commonSlice'
import ConfirmationMessage from '../../components/notification/ConfirmationMessage'
import ModalHeader from '../../components/modal/ModalHeader'
import { IAssociateStatus } from '../../models/associateListInterfaces'
import { IImageInput, IRemoveImage, IResentEmail } from '../../models/commonInterfaces'
import Paginations from '../../components/Pagination'
import { commonModelStyle } from '../../config/constants'




const ClientList = () => {
    const { token, unid, loginUserRole } = useAppSelector(loginPageSlices);
    const { clientListData, paginationTotalCount, clientData, loading, imageId, uploadedImg, uploadedProfileImg, imgProfileId } = useAppSelector(ClientListSlices);
    const { stateData } = useAppSelector(CommonSlices);
    const dispatch = useAppDispatch();

    //Paginaton
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;
    //PERMISSION
    const checkViewPermission = () => { return loginUserRole === USER_ROLE_SUPERADMIN };
    const hasResendEmailPermission = (loginUserRole === USER_ROLE_SUPERADMIN)

    const [clientName, setClientName] = useState<string>('');
    const [streetOne, setStreetOne] = useState<string>('');
    const [streetTwo, setStreetTwo] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [zipCode, setZipCode] = useState<string>('');
    const [primaryContactFirstName, setPrimaryContactFirstName] = useState<string>('');
    const [primaryContactMiddleName, setPrimaryContactMiddleName] = useState<string>('');
    const [primaryContactLastName, setPrimaryContactLastName] = useState<string>('');
    const [emailNotification, setEmailNotification] = useState<string>('2');
    const [primaryMobile, setPrimaryMobile] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [resendEmail, setresendEmail] = useState<boolean>(false);
    const [userEmailId, setUserEmailId] = useState<number>();
    const [sortField, setSortField] = useState<string>("full_name");
    const [openCreateClient, setOpenCreateClient] = useState<boolean>(false);
    const [viewOnly, setviewOnly] = useState<boolean>(false);
    const [addClient, setAddClient] = useState<boolean>(true);
    const [mobile, setMobile] = useState<string>('');
    const [alternativeMobile, setAlternativeMobile] = useState<string>('');
    const [logoImage, setLogoImage] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [imageProfileId, setImageProfileId] = useState<number | null>(null);
    const [imageProId, setImageProId] = useState<number | null>(null);
    const [userId, setUserId] = useState<string>('');
    const [showStatusConfirmation, setShowStatusConfirmation] = useState<boolean>(false);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [storeStatusData, setStoreStatusData] = useState<any>();

    //sign

    // const [signImage, setsignImage] = useState<string>('');
    // const [signImageId, setSignImageId] = useState<number | null>(null);
    // const [sign, setSign] = useState<any>();

    //initial fetching the data to display item on table
    useEffect(() => {
        if (checkViewPermission()) {
            const req: IClientList = {
                unid, token, page: currentPage
            };
            dispatch(fetchClientList(req))
        }
    }, [])

    //use to reload the table data when dispatch oru other functionality is applied
    useEffect(() => {
        if (loading === 'success' && checkViewPermission()) {
            const req: IClientList = {
                unid, token, page: currentPage
            };
            dispatch(fetchClientList(req))

            if (openCreateClient) {
                handleClientReset();
            }
        }
    }, [loading])

    //to handle edit client 
    useEffect(() => {
        if (clientData.length > 0) {
            const userDetails = clientData[0]
            setAddClient(false);
            setClientName(userDetails.name);
            setStreetOne(userDetails.location.street1);
            setStreetTwo(userDetails.location.street2);
            setCity(userDetails.location.city);
            setState(userDetails.location.state.toString());
            setZipCode(userDetails.location.zip_code);
            setPrimaryContactFirstName(userDetails.user.first_name);
            setPrimaryContactMiddleName(userDetails.user.middle_name);
            setPrimaryContactLastName(userDetails.user.last_name);
            setPrimaryMobile(userDetails.user.mobile);
            setEmail(userDetails.user.email);
            setresendEmail(userDetails.email_verified);
            setUserEmailId(userDetails.user_id);
            setMobile(userDetails.mobile);
            setAlternativeMobile(userDetails.alternate_phone);
            setLogoImage(userDetails.logo_url ? userDetails.logo_url : '');
            setProfileImage(userDetails.profile_pic_url ? userDetails.profile_pic_url : '');
            setImageProId(userDetails.logo)
            setImageProfileId(userDetails.profile_pic);
            setEmailNotification(userDetails.notification ? userDetails.notification.toString() : '2');
            // setsignImage(userDetails.sign_url);
            // setSignImageId(userDetails.sign)

        }
    }, [clientData]);


    useEffect(() => {
        setImageProId(imageId);
        setLogoImage(uploadedImg);
    }, [imageId, uploadedImg])


    // useEffect(() => {
    //     setsignImage(uploadedSignImg);
    // }, [uploadedSignImg])

    // useEffect(() => {
    //     setSignImageId(imageSignId);
    // }, [imageSignId])

    useEffect(() => {
        setImageProfileId(imgProfileId);
        setProfileImage(uploadedProfileImg);
    }, [uploadedProfileImg, imgProfileId])

    //save button function on modal
    const handleSaveClientData = (e: any) => {
        e.preventDefault();
        if (clientName.length > 0 && streetOne.length > 0 && city.length > 0 && state.length > 0 && zipCode.length > 0 && primaryContactFirstName.length > 0 && primaryContactLastName.length > 0 && email.length) {
            if (mobile.length < 2 || mobile.length > 6) {

                const req: IClientData = {
                    unid,
                    token,
                    uuid: userId,
                    client: {
                        name: clientName,
                        mobile: mobile,
                        alternate_phone: alternativeMobile,
                        logo: imageProId
                    },
                    location: {
                        street1: streetOne,
                        street2: streetTwo,
                        city: city,
                        state: Number(state),
                        country: DEFAULT_COUNTRY,
                        zip_code: zipCode,
                    },
                    user_info: {
                        first_name: primaryContactFirstName,
                        middle_name: primaryContactMiddleName,
                        last_name: primaryContactLastName,
                        email: email,
                        profile_pic: imageProfileId,
                        mobile: primaryMobile,
                        notifications: Number(emailNotification),
                        // sign: signImage === '' ? null : signImageId,
                        // sign_base: signImage === '' ? (loginUserRole === USER_ROLE_CLIENT_ADMIN && sign.isEmpty()) ? "" : loginUserRole === USER_ROLE_CLIENT_ADMIN ? sign.getTrimmedCanvas().toDataURL('image/png') : '' : '',
                    }
                }
                if (addClient) {
                    //for add client
                    dispatch(addClientData(req));
                } else {
                    //for update client
                    dispatch(updateClientData(req));
                }
            } else {
                setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveClientData');
            }
        } else {
            setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveClientData');
        }
    }

    //edit click on table
    const handleClickEdit = (data: any) => {
        const uuid = data.uuid;
        setUserId(uuid);
        const req: IClientList = {
            unid, token, uuid
        };
        dispatch(editClientData(req));
        setOpenCreateClient(true);
    }

    //View click on table
    const handleClickView = (data: any) => {
        setviewOnly(true);
        handleClickEdit(data);
    }

    //function handles Deactivate and Activation of client 
    const handleConfiramation = () => {
        const uuid = storeStatusData.uuid
        const req: IAssociateStatus = {
            unid, token, uuid, action: storeStatusData.active_status === STATUE_ACTIVE ? 1 : 2
        };
        dispatch(updateStatus(req));
        setShowStatusConfirmation(false);
    }

    //confirmation popup 
    const handleClickStatus = (data: any) => {
        setShowStatusConfirmation(true);
        setStoreStatusData(data);
        setStoreStatus(data.active_status === STATUE_ACTIVE)
    }

    //for reset all value in modal
    const handleClientReset = () => {
        setClientName('');
        setStreetOne('');
        setStreetTwo('');
        setCity('');
        setState('0');
        setZipCode('');
        setPrimaryContactFirstName('');
        setPrimaryContactMiddleName('');
        setPrimaryContactLastName('');
        setPrimaryMobile('');
        setEmail('');
        setresendEmail(false);
        setMobile('');
        setAlternativeMobile('');
        setLogoImage('');
        setProfileImage('');
        setImageProId(null);
        setImageProfileId(null);
        setOpenCreateClient(!openCreateClient);
        setviewOnly(false);
        setAddClient(true);
        setUserId('');
        setEmailNotification('2');
        // setsignImage("");
        // setSignImageId(null);
    }


    //for add profile image
    const handleAddImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_CLIENT, image_type: LOGO_UPLOAD_LOGO
        }
        dispatch(addImageInput(req));
    }
    const handleAddProfileImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
        }
        dispatch(addImageProfileInput(req));
    }
    //remove profile image
    const handleRemoveImage = () => {
        if (imageProId && imageProId !== null) {
            const req: IRemoveImage = {
                token, unid, fid: imageProId
            }
            dispatch(removeImageInput(req));
        }
    }

    const handleRemoveProfielImage = () => {
        if (imageProfileId && imageProfileId !== null) {
            const req: IRemoveImage = {
                token, unid, fid: imageProfileId
            }
            dispatch(removeImageProfileInput(req));
        }
    }


    //Resent email 
    const onResendEmail = (data: any) => {
        const req: IResentEmail = {
            unid, token, user_id: data.user_id ?? null, user_email: data.pc_email

        };
        dispatch(resentVerificationClientListMail(req));
    }


    //for add profile image
    // const handleAddSign = (e: ChangeEvent<HTMLInputElement>) => {
    //     const selectedFile = e.target.files?.[0];

    //     if (selectedFile) {
    //         const req: IImageInput = {
    //             token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_SIGN
    //         }
    //         dispatch(addClientAdminSignInput(req));
    //     }
    //     e.target.value = '';
    // }

    //remove sign image
    // const handleRemoveSign = () => {
    //     if (signImage === '' && loginUserRole === USER_ROLE_CLIENT_ADMIN) {
    //         sign.clear();
    //     }
    //     setsignImage('');
    //     if (signImageId !== null) {
    //         const req: IRemoveImage = {
    //             token, unid, fid: signImageId
    //         }
    //         dispatch(removeClientAdminSignInput(req));

    //     }
    //     setSignImageId(null);
    // }
    //Filter and sorting
    const handleClientList = (value: any, field: string) => {
        const req: IClientList = {
            unid, token,
            page: (field === 'pagination') ? value : currentPage,
            sort: (field === 'sort_client') ? value : sortField,
        };
        dispatch(fetchClientList(req))

    }

    // pagination    
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        if (checkViewPermission()) {
            handleClientList(page, 'pagination');

            // const req: IClientList = {
            //     unid, token, page: page
            // };
            // dispatch(fetchClientList(req))
        }
    };
    return (
        <div className='w-full min-h-[calc(100vh-157px)]'>
            <div className="flex justify-between items-center  pt-4 pb-[45px] pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>All Clients</h1>
                </div>
                <div>
                    <ButtonType type='iconLeft' buttonLabel='Add Client' icon={plusIcon} onClick={() => handleClientReset()} />
                </div>
            </div>
            <div className='pl-12 pr-8 '>
                <Table
                    parentPage='Client Admin'
                    setPermission={false}
                    titles={clientListHeader}
                    data={clientListData}
                    currentPage={currentPage}
                    numRowsPerPage={itemsPerPage}
                    hasResendEmailPermission={hasResendEmailPermission}
                    sortedFieldName={sortField}
                    handleSort={(sortField: string) => {
                        setSortField(sortField);
                        handleClientList(sortField, 'sort_client');
                    }}
                    onClickEdit={(uuid: any) => handleClickEdit(uuid)}
                    onClickView={(uuid: any) => handleClickView(uuid)}
                    onClickStatus={(obj: any) => handleClickStatus(obj)}
                    onResendEmail={(obj: any) => onResendEmail(obj)}
                    className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : ' mb-28 '}
                />
            </div>
            <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
            />
            <Modal
                open={openCreateClient}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={commonModelStyle} className='focus:outline-none w-full max-w-[936px]'>

                    <ModalHeader title={addClient ? 'Add Client' : viewOnly ? 'Client Information' : 'Update Client'} onClick={() => handleClientReset()} />
                    <form className='max-h-[calc(100vh-200px)] custom-scrollBar' onSubmit={(e) => handleSaveClientData(e)}>
                        <div className="px-9">


                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="flex flex-col gap-9 w-full">
                                    <TextField
                                        label=' Client Name'
                                        required={true}
                                        value={clientName}
                                        disabled={viewOnly}
                                        onChange={e => setClientName(e.target.value)}
                                        placeholder=' Client Name' />
                                    <TextField
                                        label=' Street 1'
                                        required={true}
                                        value={streetOne}
                                        disabled={viewOnly}
                                        onChange={e => setStreetOne(e.target.value)}
                                        placeholder=' Street 1' />
                                </div>
                                <ImageInput
                                    label='Logo'
                                    squareShape={true}
                                    profileImage={logoImage}
                                    handleAddImage={handleAddImage}
                                    handleRemoveImage={handleRemoveImage} />

                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' Street 2'
                                    value={streetTwo}
                                    disabled={viewOnly}
                                    onChange={e => setStreetTwo(e.target.value)}
                                    placeholder=' Street 2' />
                                <TextField
                                    label=' City'
                                    required={true}
                                    value={city}
                                    disabled={viewOnly}
                                    onChange={e => setCity(e.target.value)}
                                    placeholder=' City' />

                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <SelectField
                                    search={true}
                                    // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                                    onChange={(e) => setState(e.target.value)}
                                    name='state'
                                    id='state'
                                    label='State'
                                    value={state}
                                    disabled={viewOnly}
                                    required={true}
                                    placeHolder={true}
                                    options={stateData}
                                />

                                <TextField
                                    label='ZIP Code'
                                    type='text'
                                    required={true}
                                    value={zipCode}
                                    disabled={viewOnly}
                                    onChange={e => setZipCode(filterDigitsOnly(e.target.value))}
                                    placeholder='ZIP Code'
                                />

                            </div>


                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                                <PhoneInputFeild
                                    inputProps={{
                                        name: `mobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={mobile}
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    label='Mobile'
                                    placeholder='Mobile Number'
                                    onChange={phone => setMobile(phone)} />
                                <div className="w-full"></div>


                            </div>
                            <div className=" title-border-b ">
                                Primary User Information
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="flex flex-col gap-9 w-full">
                                    <TextField
                                        label='First Name'
                                        required={true}
                                        value={primaryContactFirstName}
                                        disabled={viewOnly}
                                        onChange={e => setPrimaryContactFirstName(e.target.value)}
                                        placeholder='First Name' />
                                    <TextField
                                        label='Middle Name'
                                        value={primaryContactMiddleName}
                                        disabled={viewOnly}
                                        onChange={e => setPrimaryContactMiddleName(e.target.value)}
                                        placeholder='Middle Name' />
                                </div>
                                <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddProfileImage} handleRemoveImage={handleRemoveProfielImage} />

                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label='Last Name'
                                    required={true}
                                    value={primaryContactLastName}
                                    disabled={viewOnly}
                                    onChange={e => setPrimaryContactLastName(e.target.value)}
                                    placeholder='Last Name' />
                                <div className="w-full"></div>
                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                                <PhoneInputFeild
                                    inputProps={{
                                        name: `primaryMobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={primaryMobile}
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    label='Mobile'
                                    placeholder='Mobile Number'
                                    onChange={phone => setPrimaryMobile(phone)} />

                                <div className="w-full flex flex-col gap-1">

                                    <TextField
                                        type='email'
                                        label='Email'
                                        value={email}
                                        disabled={viewOnly}
                                        onChange={e => setEmail(e.target.value)}
                                        required={true}
                                        placeholder='Email' />
                                    {(!addClient && !resendEmail && hasResendEmailPermission) && <div className='w-full text-right'>
                                        <sup
                                            className="cursor-pointer text-[var(--color-0048D4)] hover:underline"
                                            onClick={() => onResendEmail({ user_id: userEmailId, pc_email: email })}
                                        >
                                            Resend email
                                        </sup>
                                    </div>}
                                </div>
                            </div>
                            <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">
                                <div className="w-full">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            Email Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes" name="email_notification" value="1" disabled={viewOnly} checked={emailNotification === '1'} onClick={() => setEmailNotification('1')} /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No" name="email_notification" value="2" disabled={viewOnly} checked={emailNotification === '2'} onClick={() => setEmailNotification('2')} /><label htmlFor="No">No</label></div>

                                    </div>

                                </div>
                                <div className="w-full"> &nbsp;</div>

                            </div>
                            {/* <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">

                                <div className="w-full">
                                    <span className="text-[15px] text-[var(--color-212121)] tracking-[0.05px]">
                                        {loginUserRole === USER_ROLE_CLIENT_ADMIN ? <>Signature (Sign or Upload Image)</> : <>Signature (Upload Image)</>}
                                    </span>
                                    <div className="flex mt-[18px] flex-col gap-1">
                                        <div className="flex justify-center items-center border border-[var(--color-9E9E9E)] rounded-xl h-[175px]  overflow-hidden object-fit">

                                            {(signImage !== '' || viewOnly) ? 
                                            <img src={signImage} alt="" className='h-[175px] w-auto min-h-[100px]' /> : loginUserRole === USER_ROLE_CLIENT_ADMIN ? <ReactSignatureCanvas
                                                canvasProps={{ className: 'sigCanvas w-full h-full ' }}
                                                ref={(data: any) => setSign(data)}
                                            /> : <></>}
                                        </div>
                                        {!viewOnly && <div className="flex justify-between items-center">
                                            <label htmlFor='signImage' className="relative text-[var(--color-424242)] text-xs tracking-[0.05px] w-fit font-medium cursor-pointer">
                                                Upload Sign Image
                                                <input
                                                    name='signImage'
                                                    id='signImage'
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    className="hidden"
                                                    onChange={(e) => handleAddSign(e)}
                                                />
                                            </label>
                                            <span className='text-right text-[var(--color-424242)] text-xs tracking-[0.05px] font-medium cursor-pointer ' onClick={() => {
                                                handleRemoveSign();
                                            }}>Clear</span></div>}
                                    </div>
                                </div>
                                <div className='w-full'></div>
                            </div> */}
                            {!viewOnly && <>
                                <hr className='border-[var(--color-E0E0E0)] ' />
                                <div className="flex gap-4 justify-end items-end my-9">
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Cancel'
                                        onClick={() => handleClientReset()}
                                        className='px-12 !important bg-[var(--color-white)] !important imp-text-424242 hover:bg-[var(--color-0000000a)]' />
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Save'
                                        buttonType='submit'
                                        className='px-12 bg-[var(--color-0048D4)]' />
                                </div></>}
                        </div>
                    </form>
                </Box>
            </Modal>
            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={`Are you sure you want to ${storeStatus ? 'deactivate' : 'activate'} this account?`}
                open={showStatusConfirmation}
                textType={false}
                type={storeStatus ? 'danger' : 'success'}
                buttonTwoOnClick={() => setShowStatusConfirmation(false)}
                buttonOneOnClick={() => handleConfiramation()}
                buttonLabelOne={storeStatus ? 'Deactivate' : 'Activate'}
                buttonLabelTwo='Cancel'
            />
        </div>
    )
}

export default ClientList;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { IMessageTemplateState } from "../../models/messageTemplateInterface";
import { addmessageTemplate, deletemessageTemplateData, fetchMessageTemplateList, updatemessageTemplateData } from "../../services/messageTemplateServices";

export interface MessageTemplateState {
  loading: "idle" | "loading" | "success" | "fetchSuccessfully" | "error";
  messageTemplateData: IMessageTemplateState[];
  paginationTotalCount: number;
}

const initialState: MessageTemplateState = {
  loading: "idle",
  messageTemplateData: [],
  paginationTotalCount: 0,
};

export const messageTemplateDataSlice = createSlice({
  name: "messageTemplateDataSlice",
  initialState,
  reducers: {
    changeLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMessageTemplateList.pending, (state) => {
      state.paginationTotalCount = 0;
      state.loading = "loading";
      state.messageTemplateData =[];
    });
    builder.addCase(fetchMessageTemplateList.fulfilled, (state, action) => {
      state.paginationTotalCount = action.payload.count;
      state.messageTemplateData = action.payload.results as IMessageTemplateState[];
      state.loading = "fetchSuccessfully";
    });
    builder.addCase(fetchMessageTemplateList.rejected, (state) => {
      state.loading = "error";
    });

    builder.addCase(addmessageTemplate.pending, (state) => {
      state.loading = "idle";
    });
    builder.addCase(addmessageTemplate.fulfilled, (state) => {
      state.loading = "success";
      setNotification(
        message.CFPCareConnect,
        message.messageTemplateAddedSuccessfully,
        false,
        "success",
        "messageTemplateDataSlice"
      );
    });
    builder.addCase(addmessageTemplate.rejected, (state) => {
      state.loading = "error";
      
        setNotification(
          message.CFPCareConnect,
          message.messageTemplateAddedfailed,
          false,
          "failed",
          "messageTemplateDataSlice"
        );
      
    });

    builder.addCase(updatemessageTemplateData.pending, (state) => {
      state.loading = "idle";
    });
    builder.addCase(updatemessageTemplateData.fulfilled, (state) => {
      state.loading = "success";
      setNotification(
        message.CFPCareConnect,
        message.messageTemplateUpdatedSuccessfully,
        false,
        "success",
        "messageTemplateDataSlice"
      );
    });
    builder.addCase(updatemessageTemplateData.rejected, (state) => {
      state.loading = "error";
      setNotification(
        message.CFPCareConnect,
        message.messageTemplateUpdatedfailed,
        false,
        "failed",
        "messageTemplateDataSlice"
      );
    });

    
    builder.addCase(deletemessageTemplateData.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(deletemessageTemplateData.fulfilled, (state) => {
      state.loading = "success";
      setNotification(
        message.CFPCareConnect,
        message.messageTemplateDeletedSuccessfully,
        false,
        "success",
        "messageTemplateDataSlice"
      );
    });
    builder.addCase(deletemessageTemplateData.rejected, (state) => {
      state.loading = "error";
        setNotification(
          message.CFPCareConnect,
          message.messageTemplateDeletedfailed,
          false,
          "failed",
          "messageTemplateDataSlice"
        );
    });

  },
});

// Action creators are generated for each case reducer function
export const { changeLoading } = messageTemplateDataSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const MessageTemplatDataSlices = (state: RootState) =>
  state.messageTemplateData;

export const messageTemplateDataReducer = messageTemplateDataSlice.reducer;

// ProtectedRoutes.tsx
import React, { useMemo } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import {
    ROUTE_LOGIN,
    ROUTE_LOGIN_PATIENT,
    ROUTE_404,
    ROUTE_FACILITY_LIST,
    ROUTE_PROVIDER_LIST,
    ROUTE_NURSING_STAFF_LIST,
    ROUTE_ASSOCIATE_USERLIST,
    ROUTE_TIMESHEET,
    ROUTE_ROLE_PERMISSION,
    ROUTE_PATIENT_LIST,
} from '../../config/routeConfig';
import Navbar from '../../components/navbar/Navbar';
import SideBar from '../../components/sidebar/SideBar';
import Footer from '../../components/footer/Footer';
import { useAppSelector } from '../../helpers/hook';
import { loginPageSlices } from '../../pages/loginPage/loginPageSlice';
import { ROLE_CLIENT_ASSOCIATE_USER, ROLE_FACILITY, ROLE_NURSING_STAFF, ROLE_PATIENTS_RELATIVES, ROLE_PROVIDER, ROLE_REPORTS, ROLE_ROLE_AND_PERMISSIONS, USER_ROLE_PATIENTS, USER_ROLE_RELATIVES } from '../../config/permissions';

// Map correct routes to permissions from the backend
const ROUTE_PERMISSIONS_MAP: Record<string, string> = {
    [ROUTE_FACILITY_LIST]: ROLE_FACILITY,
    [ROUTE_PROVIDER_LIST]: ROLE_PROVIDER,
    [ROUTE_NURSING_STAFF_LIST]: ROLE_NURSING_STAFF,
    [ROUTE_ASSOCIATE_USERLIST]: ROLE_CLIENT_ASSOCIATE_USER,
    [ROUTE_TIMESHEET]: ROLE_REPORTS,
    [ROUTE_ROLE_PERMISSION]: ROLE_ROLE_AND_PERMISSIONS,
    [ROUTE_PATIENT_LIST]: ROLE_PATIENTS_RELATIVES,
};

const ProtectedRoutes: React.FC = () => {
    const { token, unid, loginUserRole, userPermissions } = useAppSelector(loginPageSlices);
    const location = useLocation();

    // Check if the user is authenticated
    const isAuthenticated = useMemo(
        () => token?.length > 0 && unid?.length > 0,
        [token, unid]
    );

    // Check if the user has permission for the current route
    const hasPermission = useMemo(() => {
        const currentPath = location.pathname;
        const requiredPermission = ROUTE_PERMISSIONS_MAP[currentPath];

        // If no permission is required, allow access
        if (!requiredPermission) return true;

        // Check if the user has the required permission
        return requiredPermission in userPermissions;
    }, [location.pathname, userPermissions]);

    // Redirect to login if not authenticated
    if (!isAuthenticated) {
        return loginUserRole === USER_ROLE_PATIENTS || loginUserRole === USER_ROLE_RELATIVES ? (
            <Navigate to={ROUTE_LOGIN_PATIENT} replace />
        ) : (
            <Navigate to={ROUTE_LOGIN} replace />
        );
    }

    // Redirect to 404 if the user lacks permission
    if (!hasPermission) {
        return <Navigate to={ROUTE_404} replace />;
    }

    // Render layout and protected content
    return (
        <>
            <Navbar />
            <div className="flex w-full">
                <SideBar />
                <div className="flex flex-col justify-between w-full">
                    <Outlet />
                    <Footer />
                </div>
            </div>
        </>
    );
};

export default ProtectedRoutes;

import {
  ACTION_MENU_APPROVE,
  ACTION_MENU_DEACTIVATE,
  ACTION_MENU_DELETE,
  ACTION_MENU_EDIT,
  ACTION_MENU_VIEW,
} from "../../config/globalValues";

export const nursingStaffHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "full_name",
    type: "text",
    display_title: "Name",
    sortBy: true,
    sortDirection: "asc",
    sortTitle: "name",
    onClickable: false,
    profile_pic: true,
  },
  {
    title: "email",
    type: "email",
    display_title: "Email",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "email",
  },
  {
    title: "mobile",
    type: "mobile",
    display_title: "Phone",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "mobile",
  },
  // {
  //     title: 'alternate_mobile',
  //     type: 'mobile',
  //     display_title: 'Alternative Phone',
  //     sortBy: false,
  //     sortDirection: 'asc',
  //     sortTitle: 'mobile',
  // },
  {
    title: "facility_name",
    type: "rowMultiData",
    display_title: "Facilities",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "account",
  },
  {
    title: "active",
    type: "multiStatus",
    display_title: "Status",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "active",
  },
  {
    title: "multiActionMenu",
    type: "AED",
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];
export const clientListHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "name",
    type: "text",
    display_title: "Name",
    sortBy: true,
    sortDirection: "asc",
    sortTitle: "name",
    onClickable: false,
  },
  {
    title: "address",
    type: "text",
    display_title: "Address",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "address",
  },
  {
    title: "mobile",
    type: "mobile",
    display_title: "Phone",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "mobile",
  },
  // {
  //     title: 'alternate_phone',
  //     type: 'mobile',
  //     display_title: 'Alternative Phone',
  //     sortBy: false,
  //     sortDirection: 'asc',
  //     sortTitle: 'alternative_phone',
  // },
  {
    title: "pc_name",
    type: "text",
    display_title: "Primary Contact",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "primary_contact",
  },
  {
    title: "pc_email",
    type: "email",
    display_title: "PC Email",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "pc_email",
  },
  {
    title: "pc_mobile",
    type: "mobile",
    display_title: "PC Phone",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "pc_phone",
  },
  {
    title: "active",
    type: "status",
    display_title: "Status",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "active",
  },
  {
    title: "actionMenu",
    type: [ACTION_MENU_VIEW, ACTION_MENU_EDIT, ACTION_MENU_DEACTIVATE],
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

export const facilityListHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "name",
    type: "text",
    display_title: "Facility Name",
    sortBy: true,
    sortDirection: "asc",
    sortTitle: "facility_name",
    onClickable: false,
  },

  {
    title: "address",
    type: "text",
    display_title: "Address",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "address",
  },
  {
    title: "mobile",
    type: "mobile",
    display_title: "Phone",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "mobile",
  },
  // {
  //     title: 'alternate_phone',
  //     type: 'mobile',
  //     display_title: 'Alternative Phone',
  //     sortBy: false,
  //     sortDirection: 'asc',
  //     sortTitle: 'mobile',
  // },

  {
    title: "active",
    type: "status",
    display_title: "Status",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "active",
  },

  {
    title: "actionMenu",
    type: [ACTION_MENU_VIEW, ACTION_MENU_EDIT, ACTION_MENU_DEACTIVATE],
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

export const providerListHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "full_name",
    type: "text",
    display_title: "Name",
    sortBy: true,
    sortDirection: "asc",
    sortTitle: "name",
    onClickable: false,
    profile_pic: true,
  },
  {
    title: "email",
    type: "email",
    display_title: "Email",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "email",
  },
  {
    title: "mobile",
    type: "mobile",
    display_title: "Phone",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "mobile",
  },
  // {
  //     title: 'alternate_mobile',
  //     type: 'mobile',
  //     display_title: 'Alternative Phone',
  //     sortBy: false,
  //     sortDirection: 'asc',
  //     sortTitle: 'mobile',
  // },
  {
    title: "facility_name",
    type: "rowMultiData",
    display_title: "Facilities",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "account",
  },
  {
    title: "active",
    type: "multiStatus",
    display_title: "Status",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "active",
  },
  {
    title: "multiActionMenu",
    type: "ED",
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

export const patientListHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "patient_name",
    type: "link",
    display_title: "Patient Name",
    sortBy: true,
    sortDirection: "asc",
    sortTitle: "patient_name",
    onClickable: false,
    profile_pic: true,
  },
  {
    title: "member_name",
    type: "text",
    display_title: "Family Member Name",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "family_member",
  },
  {
    title: "relationship",
    type: "text",
    display_title: "Relationship",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "relationship",
  },
  {
    title: "patient_dob",
    type: "date",
    display_title: "DOB",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "dob",
  },
  {
    title: "email",
    type: "email",
    display_title: "Email",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "email",
  },
  {
    title: "facility_name",
    type: "text",
    display_title: "Facility",
    sortBy: true,
    sortDirection: "asc",
    sortTitle: "facility_name",
  },

  {
    title: "mobile",
    type: "mobile",
    display_title: "Phone",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "mobile",
  },
  {
    title: "created_on",
    type: "date",
    display_title: "Added Date",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "mobile",
  },
  {
    title: "active",
    type: "status",
    display_title: "Status",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "active",
  },
  {
    title: "actionMenu",
    type: [
      ACTION_MENU_APPROVE,
      ACTION_MENU_VIEW,
      ACTION_MENU_EDIT,
      ACTION_MENU_DEACTIVATE,
    ],
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

export const associateUserListHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "full_name",
    type: "text",
    display_title: "Name",
    sortBy: true,
    sortDirection: "asc",
    sortTitle: "name",
    onClickable: false,
    profile_pic: true,
  },
  {
    title: "email",
    type: "email",
    display_title: "Email",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "email",
  },
  {
    title: "mobile",
    type: "mobile",
    display_title: "Phone",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "mobile",
  },
  // {
  //     title: 'alternate_mobile',
  //     type: 'mobile',
  //     display_title: 'Alternative Phone',
  //     sortBy: false,
  //     sortDirection: 'asc',
  //     sortTitle: 'mobile',
  // },
  {
    title: "facility_name",
    type: "rowMultiData",
    display_title: "Facilities Assigned",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "facilities",
  },
  {
    title: "active",
    type: "multiStatus",
    display_title: "Status",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "active",
  },
  {
    title: "multiActionMenu",
    type: "ED",
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

export const timesheetHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "patient_name",
    type: "text",
    display_title: "Patient",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "patient",
    onClickable: false,
  },
  {
    title: "gender",
    type: "text",
    display_title: "Gender",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "dob",
    type: "date",
    display_title: "DOB",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "provider_name",
    type: "text",
    display_title: "Associate/Provider Name",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "facility",
    type: "text",
    display_title: "Facility",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "date",
    type: "date_time",
    display_title: "Date & Time",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "date",
  },
  {
    title: "time",
    type: "timesheetTime",
    display_title: "Time Taken (min:sec)",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "time",
  },
];

export const timesheetSummaryHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "patient_name",
    type: "text",
    display_title: "Patient",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "patient",
    onClickable: false,
  },
  {
    title: "gender",
    type: "text",
    display_title: "Gender",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "dob",
    type: "date",
    display_title: "DOB",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "facility",
    type: "text",
    display_title: "Facility",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "time",
    type: "timesheetTime",
    display_title: "Time Taken (min:sec)",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "time",
  },
];

export const PatientProvideSummaryHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "patient_name",
    type: "text",
    display_title: "Patient",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "patient",
    onClickable: false,
  },
  {
    title: "gender",
    type: "text",
    display_title: "Gender",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "dob",
    type: "date",
    display_title: "DOB",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "provider_name",
    type: "text",
    display_title: "Associate/Provider Name",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "facility",
    type: "text",
    display_title: "Facility",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "provide",
  },
  {
    title: "time",
    type: "timesheetTime",
    display_title: "Time Taken (min:sec)",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "time",
  },
];

export const RoleListHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "name",
    type: "text",
    display_title: "Roles",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "roles",
    onClickable: false,
  },
  {
    title: "actionMenu",
    type: [ACTION_MENU_VIEW, ACTION_MENU_EDIT, ACTION_MENU_DEACTIVATE],
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

export const AutoReplyHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "date",
    type: "date",
    display_title: "Holiday",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "holiday",
    onClickable: false,
  },
  {
    title: "title",
    type: "text",
    display_title: "Remarks",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "remarks",
    onClickable: false,
  },
  {
    title: "actionMenu",
    type: [ACTION_MENU_EDIT, ACTION_MENU_DELETE],
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

export const MessageTemplateListHeader = [
  {
    title: "serialNumber",
    type: "serial",
    display_title: "#",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "serial_number",
    onClickable: false,
  },
  {
    title: "title",
    type: "text",
    display_title: "Title",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "title",
    onClickable: false,
  },
  {
    title: "actionMenu",
    type: [ACTION_MENU_EDIT, ACTION_MENU_VIEW, ACTION_MENU_DELETE],
    display_title: "Action",
    sortBy: false,
    sortDirection: "asc",
    sortTitle: "",
  },
];

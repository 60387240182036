import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import { useAppSelector } from "../../helpers/hook";
import { loginPageSlices } from "../../pages/loginPage/loginPageSlice";
import { FamilyRegistrationSlices } from "../../pages/familyMemberRegistration/familyMemberRegistrationSlice";
import ReactLoading from "react-loading";
import { ClientListSlices } from "../../pages/clientList/clientListSlice";
import { ResetPasswordSlices } from "../../pages/resetPassword/resetPasswordSlice";
import { AssociateListSlices } from "../../pages/associateUserList/associateListSlice";
import { NursingStaffSlices } from "../../pages/nursingStaffList/nursingStaffListSlice";
import { FacilityListSlices } from "../../pages/facilityList/facilityListSlice";
import { ProviderListSlices } from "../../pages/providerList/providerListSlice";
import { PatientListSlices } from "../../pages/patientList/patientListSlice";
import { DashboardSlices } from "../../pages/dashboard/dashboardSlice";
import { PatientRegistrationSlices } from "../../pages/patientRegistration/patientRegistrationSlice";
import { NursingStaffRegistrationSlices } from "../../pages/nursingStaffRegistration/nursingStaffRegSlice";
import { RolesListSlices } from "../../pages/rolesAndPermission/rolesAndPermissionSlice";
import { NotificationListSlices } from "../../pages/notificationList/notificationSlice";
import { TimeSheetSlices } from "../../pages/timeSheet/timeSheetSlice";
import { useLocation } from "react-router-dom";
import { ROUTE_LOGIN, ROUTE_LOGIN_PATIENT, ROUTE_RESET_PASSWORD } from "../../config/routeConfig";
import { AutoReplyListSlices } from "../../pages/autoReply/autoReplySlice";
import { MessageTemplatDataSlices } from "../../pages/messageTemplate/messageTemplateSlice";

export const SetLoader = () => {
    const location = useLocation();
    const loginPageLoading = useAppSelector(loginPageSlices);
    const familyRegisterLoading = useAppSelector(FamilyRegistrationSlices);
    const patientRegisterLoading = useAppSelector(PatientRegistrationSlices);
    const nursingRegisterLoading = useAppSelector(NursingStaffRegistrationSlices);
    const clientListLoading = useAppSelector(ClientListSlices);
    const setPasswordLoading = useAppSelector(ResetPasswordSlices);
    const associateUserLoading = useAppSelector(AssociateListSlices);
    const nursingStaffLoading = useAppSelector(NursingStaffSlices);
    const facilityListLoading = useAppSelector(FacilityListSlices);
    const providerLoading = useAppSelector(ProviderListSlices);
    const patientLoading = useAppSelector(PatientListSlices);
    const messageLoading = useAppSelector(DashboardSlices);
    const rolesLoading = useAppSelector(RolesListSlices);
    const notificationLoading = useAppSelector(NotificationListSlices);
    const timesheetLoading = useAppSelector(TimeSheetSlices);
    const autoReplyLoading = useAppSelector(AutoReplyListSlices);
    const messageTemplateLoading = useAppSelector(MessageTemplatDataSlices);

    const [show, setshow] = useState(false)

    useEffect(() => {

        if ((location.pathname !== ROUTE_LOGIN && location.pathname !== ROUTE_LOGIN_PATIENT && location.pathname !== ROUTE_RESET_PASSWORD && location.pathname !== '/') ?
            (
                loginPageLoading.loading === "logoutLoading" ||
                clientListLoading.loading === "loading" ||
                familyRegisterLoading.loading === "loading" ||
                patientRegisterLoading.loading === "loading" ||
                nursingRegisterLoading.loading === "loading" ||
                associateUserLoading.loading === "loading" ||
                nursingStaffLoading.loading === "loading" ||
                facilityListLoading.loading === "loading" ||
                providerLoading.loading === "loading" ||
                patientLoading.loading === "loading" ||
                messageLoading.loading === "loading" ||
                rolesLoading.loading === "loading" ||
                timesheetLoading.loading === "loading" ||
                autoReplyLoading.loading === "loading" ||
                messageTemplateLoading.loading === "loading" ||
                notificationLoading.loading === "listLoading") :
            (
                (location.pathname === ROUTE_LOGIN || location.pathname === ROUTE_LOGIN_PATIENT) ?
                    loginPageLoading.loading === "loading" :
                    (location.pathname === ROUTE_RESET_PASSWORD) ?
                        setPasswordLoading.loading === 'loading' : false)
        ) {
            setshow(true);
            
        } else {
            setshow(false)
        }
    }, [
        loginPageLoading.loading,
        nursingStaffLoading.loading,
        facilityListLoading.loading,
        providerLoading.loading,
        associateUserLoading.loading,
        patientLoading.loading,
        familyRegisterLoading.loading,
        patientRegisterLoading.loading,
        nursingRegisterLoading.loading,
        clientListLoading.loading,
        setPasswordLoading.loading,
        rolesLoading.loading,
        messageLoading.loading,
        notificationLoading.loading,
        timesheetLoading.loading,
        autoReplyLoading.loading,
        messageTemplateLoading.loading,
        location.pathname
    ]);

    setTimeout(() => {
        if (show) {
            setshow(false);
        }
    }, 300000);

    return (
        <div>
            <Modal
                open={show}
                // onClose={() => setshow(false)}
                aria-labelledby={"loading"}
                aria-describedby=""
            >
                <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 border-none z-100 outline-none" >

                    <ReactLoading type={"bars"} color={'white'} className="" />

                </Box>
            </Modal>
        </div>
    );
};



import React, { useEffect, useState } from 'react';
import plusIcon from '../../assets/images/plusIcon.svg'
import { Box, Modal } from '@mui/material';
import ButtonType from '../../components/button/ButtonType';
import Table from '../../components/table';
import { MessageTemplateListHeader } from '../../components/table/tableHeaders';
import { ROLE_MESSAGE_TEMPLATE } from '../../config/permissions';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { MessageTemplatDataSlices } from './messageTemplateSlice';
import { loginPageSlices } from '../loginPage/loginPageSlice';
import ModalHeader from '../../components/modal/ModalHeader';
import { commonModelStyle } from '../../config/constants';
import TextField from '../../components/inputFeild/TextFeild';
import ConfirmationMessage from '../../components/notification/ConfirmationMessage';
import { message } from '../../utilities/locals/messages';
import { IMessageTemplateState } from '../../models/messageTemplateInterface';
import ReactQuill from 'react-quill';
import Paginations from '../../components/Pagination';
import { addmessageTemplate, deletemessageTemplateData, fetchMessageTemplateList, updatemessageTemplateData } from '../../services/messageTemplateServices';


const MessageTemplate = () => {
  const dispatch = useAppDispatch();
  const { token, unid } =
    useAppSelector(loginPageSlices);
  const { messageTemplateData, paginationTotalCount, loading } = useAppSelector(MessageTemplatDataSlices);

  const itemsPerPage = 25;
  const initialAutoReplyState = {
    unid,
    token,
    id: 0,
    title: '', template_text: '',
  }

  const [messageTemplateList, setMessageTemplateList] = useState<IMessageTemplateState>(initialAutoReplyState);
  const [page, setPage] = useState<number>(1);
  const [otherSettings, setOtherSettings] = useState({
    "dataModal": false,
    'quillValidation': false,
    'isViewOnly': false,
    "deleteConfirmModal": false
  });

  useEffect(() => {
    fetchMessageTemplateListData(page);
  }, [])

  useEffect(() => {
    if (loading === "success") {
      fetchMessageTemplateListData(page);
    }
  }, [loading])

  const fetchMessageTemplateListData = (page: number) => {
    const req: IMessageTemplateState = {
      unid,
      token, page
    }
    dispatch(fetchMessageTemplateList(req))
  }

  const handleChange = (name: string, value: string) => {
    setMessageTemplateList((prev) => ({ ...prev, [name]: value }));
  };


  // Handle form submission (add/update)
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id, ...messageTemplateData } = messageTemplateList;
    id === 0 ? dispatch(addmessageTemplate(messageTemplateData)) : dispatch(updatemessageTemplateData(messageTemplateList));
    resetData();
  };

  //edit click on table
  const handleClickView = (data: any) => {
    setMessageTemplateList(preState => ({ ...preState, id: data.id, title: data.title, template_text: data.template_text }));
    setOtherSettings(prevState => ({ ...prevState, dataModal: true, isViewOnly: true }));
  }

  //edit click on table
  const handleClickEdit = (data: any) => {
    setMessageTemplateList(preState => ({ ...preState, id: data.id, title: data.title, template_text: data.template_text }));
    setOtherSettings(prevState => ({ ...prevState, dataModal: true }));
  }

  // Delete holiday
  const handleClickDelete = (data: any) => {
    setMessageTemplateList(preState => ({ ...preState, id: data.id, title: data.title, template_text: data.template_text }));
    setOtherSettings(prevState => ({ ...prevState, deleteConfirmModal: true }));
  }

  const handleConfirmDelete = () => {
    const { id } = messageTemplateList;
    dispatch(deletemessageTemplateData({ id, unid, token }));
    resetData();
  }

  // pagination    
  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
    fetchMessageTemplateListData(pageNumber);
  };


  //reset data
  const resetData = () => {
    setMessageTemplateList(initialAutoReplyState);
    setOtherSettings({
      "dataModal": false,
      'quillValidation': false,
      'isViewOnly': false,
      "deleteConfirmModal": false
    });
  }

  return (
    <div className="w-full">
      {/* Header Section */}
      <div className="flex justify-between items-center  pt-4 pb-[45px] pl-12 pr-8">
        <div>
          <h1 className='text-2xl text-[var(--color-212121)] '>Message Templates
          </h1>
        </div>
        <div>
          <ButtonType
            type='iconLeft'
            buttonLabel='Add Template'
            icon={plusIcon}
            onClick={() => setOtherSettings(prevState => ({ ...prevState, dataModal: true }))} />
        </div>
      </div>
      {/* Holiday Table */}
      <div className="pl-12 pr-8">

        <Table
          titles={MessageTemplateListHeader}
          data={messageTemplateData}
          parentPage={ROLE_MESSAGE_TEMPLATE}
          setPermission={false}
          onClickEdit={(data: any) => handleClickEdit(data)}
          onClickDelete={(data: any) => handleClickDelete(data)}
          onClickView={(data: any) => handleClickView(data)}
          numRowsPerPage={itemsPerPage}
          className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : ' mb-28 '}
        />

      </div>

      <Paginations
        totalItems={paginationTotalCount}
        itemsPerPage={itemsPerPage}
        currentPage={page}
        setCurrentPage={handlePageChange}
      />

      <Modal
        open={otherSettings.dataModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={commonModelStyle} className='focus:outline-none w-full max-w-[936px]'>
          <ModalHeader title={messageTemplateList.id === 0 ? 'Add New Message Template' : 'Update Message Template'} onClick={() => resetData()} />

          <form className='max-h-[calc(100vh-200px)] custom-scrollBar' typeof='submit' onSubmit={(e) => handleSubmitForm(e)}>
            <div className="px-9">



              <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">

                <TextField
                  label="Template Name"
                  name="title"
                  required={true}
                  disabled={otherSettings.isViewOnly}
                  value={messageTemplateList.title}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
                <div className="w-full"> &nbsp;</div>
              </div>
              <div className=" w-full my-9">
                <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px]'>
                  Message Text <span className='text-[var(--color-red)]'>*</span>
                </span>
                <ReactQuill
                  theme="snow"
                  value={messageTemplateList.template_text}
                  onChange={(value) => handleChange("template_text", value)}
                  modules={{ toolbar: false }}
                  readOnly={otherSettings.isViewOnly}
                  className={`h-[226px]  rounded-lg border border-[var(--color-9E9E9E)] overflow-hidden ${otherSettings.quillValidation && (messageTemplateList.template_text?.length || 0) <= 0 ? "validation-ql" : ""}`} />

              </div>

              {!otherSettings.isViewOnly && <><hr className='border-[var(--color-E0E0E0)] ' />
                <div className="flex gap-4 justify-end items-end my-9">
                  <ButtonType
                    type='webCardbtn'
                    buttonLabel='Cancel'
                    onClick={() => resetData()}
                    className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                  <ButtonType
                    type='webCardbtn'
                    buttonLabel='Save'
                    buttonType='submit'
                    className='px-12 bg-[var(--color-0048D4)]' />
                </div></>
              }
            </div>
          </form>
        </Box>
      </Modal>


      <ConfirmationMessage
        title={message.CFPCareConnect}
        description={`Are you sure you want to remove this message template?`}
        open={otherSettings.deleteConfirmModal}
        textType={false}
        type={'danger'}
        buttonTwoOnClick={() => resetData()}
        buttonOneOnClick={() => handleConfirmDelete()}
        buttonLabelOne={'Delete'}
        buttonLabelTwo='Cancel'
      />
    </div>
  );
};

export default MessageTemplate;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { URL_ADD_MESSAGE_TEMPLATE,  URL_DELETE_MESSAGE_TEMPLATE,   URL_FETCH_MESSAGE_TEMPLATE, URL_UPDATE_MESSAGE_TEMPLATE } from "../config/apiUrls";
import { deleteApi, get, post, put } from "./apiService";
import { IMessageTemplateState } from "../models/messageTemplateInterface";


// to fetch all associate
export const fetchMessageTemplateList = createAsyncThunk(
  'messageTemplateDataSlice/fetchMessageTemplateList',
   async (form: IMessageTemplateState, thunkAPI) => {
      try {
        const { page, ...body } = form;
              let url = URL_FETCH_MESSAGE_TEMPLATE ;
              if (form.page&&form.page !== 0) {
                  url = url + '?page=' + page
              }
              const response = get(url, body);
              return (await response).data;
          } catch (error) {
              return thunkAPI.rejectWithValue(error);
          }
      }
);

//to add message template
export const addmessageTemplate = createAsyncThunk(
  'messageTemplateDataSlice/addmessageTemplate',
  async (form: IMessageTemplateState, thunkAPI) => {
    try {
      const response = post(URL_ADD_MESSAGE_TEMPLATE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update updatemessageTemplateData user
export const updatemessageTemplateData = createAsyncThunk(
  'messageTemplateDataSlice/updatemessageTemplateData',
  async (form: IMessageTemplateState, thunkAPI) => {
    try {
      const response = put(URL_UPDATE_MESSAGE_TEMPLATE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update updatemessageTemplateData user
export const deletemessageTemplateData = createAsyncThunk(
  'messageTemplateDataSlice/deletemessageTemplateData',
  async (form: IMessageTemplateState, thunkAPI) => {
    try {
      const response = deleteApi(URL_DELETE_MESSAGE_TEMPLATE, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
import React, { useEffect, useState } from 'react'
import ButtonType from '../../components/button/ButtonType'
import Table from '../../components/table'
import { Box, Modal } from '@mui/material'
import { RoleListHeader } from '../../components/table/tableHeaders'
import { ROLE_CLIENT_ASSOCIATE_USER, ROLE_COMPLETED_MESSAGES, ROLE_CREATE_SEND_MESSAGES, ROLE_FACILITY, ROLE_NURSING_STAFF, ROLE_PATIENTS_RELATIVES, ROLE_PROVIDER, ROLE_ROLE_AND_PERMISSIONS, ROLE_SEND_GENERAL_MESSAGES, ROLE_SEND_ORDER, ROLE_REPORTS, ROLE_VIEW_GENERAL_MESSAGES, ROLE_VIEW_INBOX_SENT_MESSAGES, ROLE_VIEW_ORDER, ROLE_PATIENTS_NAME } from '../../config/permissions'
import { fetchRoleList, fetchSingleRoleData, handleChangeRole } from '../../services/rolesAndPermissions'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { IPermission, IRoleSigleState, IRoleState, IRolesAndPermissionsData } from '../../models/rolesAndPermissionInterfaces'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { RolesListSlices } from './rolesAndPermissionSlice'
import ModalHeader from '../../components/modal/ModalHeader'
import { commonModelStyle } from '../../config/constants'





const RolesAndPermission = () => {
    const dispatch = useAppDispatch();
    const { token, unid, client } = useAppSelector(loginPageSlices);
    const { rolesListData, rolesSingleData, viewRolePermission, disabledPermission, roleName } = useAppSelector(RolesListSlices);
    const rolesToCheck = [
        ROLE_CLIENT_ASSOCIATE_USER,
        ROLE_NURSING_STAFF,
        ROLE_PATIENTS_RELATIVES,
        ROLE_PROVIDER,
        ROLE_FACILITY,
        ROLE_REPORTS,
        ROLE_ROLE_AND_PERMISSIONS,
    ];
    const meesageRolesToCheck = [
        ROLE_COMPLETED_MESSAGES,
        ROLE_CREATE_SEND_MESSAGES,
        ROLE_VIEW_INBOX_SENT_MESSAGES,
        ROLE_VIEW_GENERAL_MESSAGES,
        ROLE_SEND_GENERAL_MESSAGES,
        ROLE_SEND_ORDER,
        ROLE_VIEW_ORDER,
    ];

    const [sortField, setSortField] = useState("full_name");
    const [openCreateRole, setOpenCreateRole] = useState(false);
    const [viewOnly, setviewOnly] = useState<boolean>(false);
    const [selectedRole, setSelectedRole] = useState<number>(0);
    const [isRolePresent, setIsRolePresent] = useState<boolean>(false);
    const [isRoleMessagePresent, setIsRoleMessagePresent] = useState<boolean>(false);


    const [associate, setAssociate] = useState({ view: false, addEdit: false, status: false });
    const [facility, setFacility] = useState({ view: false, addEdit: false, status: false });
    const [provider, setProvider] = useState({ view: false, addEdit: false, status: false });
    const [nursing, setNursing] = useState({ view: false, addEdit: false, status: false, approve: false });
    const [patientFamily, setPatientFamily] = useState({ view: false, addEdit: false, status: false, approve: false });
    const [report, setReport] = useState({ view: false });
    const [roles, setRoles] = useState({ view: false, addEdit: false });
    const [message, setMessage] = useState({
        createSendMessages: false,
        viewInboxSentMessages: false,
        viewCompletedmessages: false,
        viewGeneralMessages: false,
        sendGeneralMessages: false,
        placeOrder: false,
        viewOrder: false,
    });

    const [disabledAssociate, setDisabledAssociate] = useState({ view: false, addEdit: false, status: false });
    const [disabledFacility, setDisabledFacility] = useState({ view: false, addEdit: false, status: false });
    const [disabledProvider, setDisabledProvider] = useState({ view: false, addEdit: false, status: false });
    const [disabledNursing, setDisabledNursing] = useState({ view: false, addEdit: false, status: false, approve: false });
    const [disabledPatientFamily, setDisabledPatientFamily] = useState({ view: false, addEdit: false, status: false, approve: false });
    const [disabledReport, setDisabledReport] = useState({ view: false });
    const [disabledRoles, setDisabledRoles] = useState({ view: false, addEdit: false });
    const [disabledMessage, setDisabledMessage] = useState({
        createSendMessages: false,
        viewInboxSentMessages: false,
        viewCompletedmessages: false,
        viewGeneralMessages: false,
        sendGeneralMessages: false,
        placeOrder: false,
        viewOrder: false,
    });

    const [viewAssociate, setViewAssociate] = useState({ view: false, addEdit: false, status: false });
    const [viewFacility, setViewFacility] = useState({ view: false, addEdit: false, status: false });
    const [viewProvider, setViewProvider] = useState({ view: false, addEdit: false, status: false });
    const [viewNursing, setViewNursing] = useState({ view: false, addEdit: false, status: false, approve: false });
    const [viewPatientFamily, setViewPatientFamily] = useState({ view: false, addEdit: false, status: false, approve: false });
    const [viewReport, setViewReport] = useState({ view: false });
    const [viewRoles, setViewRoles] = useState({ view: false, addEdit: false });
    const [viewMessage, setViewMessage] = useState({
        createSendMessages: false,
        viewInboxSentMessages: false,
        viewCompletedmessages: false,
        viewGeneralMessages: false,
        sendGeneralMessages: false,
        placeOrder: false,
        viewOrder: false,
    });




    //initial fetching the data to display item on table
    useEffect(() => {
        const req: IRoleState = {
            unid, token, client
        };
        dispatch(fetchRoleList(req));
    }, [])

    //To set up data single data
    useEffect(() => {
        if (Object.keys(rolesSingleData).length > 0) {
            const updatedMessage = { ...message };
            const permissions = rolesSingleData;
            if (ROLE_CLIENT_ASSOCIATE_USER in permissions) {
                const associatePermissions = permissions[ROLE_CLIENT_ASSOCIATE_USER] ?? [];
                setAssociate({
                    view: associatePermissions.includes(1) || associatePermissions.includes(2) || associatePermissions.includes(3),
                    addEdit: associatePermissions.includes(2),
                    status: associatePermissions.includes(3),
                });
            }
            if (ROLE_NURSING_STAFF in permissions) {
                const nursingPermissions = permissions[ROLE_NURSING_STAFF] ?? [];
                setNursing({
                    view: nursingPermissions.includes(1) || nursingPermissions.includes(2) || nursingPermissions.includes(3) || nursingPermissions.includes(4),
                    addEdit: nursingPermissions.includes(2),
                    status: nursingPermissions.includes(3),
                    approve: nursingPermissions.includes(4),
                });
            }
            if (ROLE_PATIENTS_RELATIVES in permissions) {
                const patientFamilyPermissions = permissions[ROLE_PATIENTS_RELATIVES] ?? [];
                setPatientFamily({
                    view: patientFamilyPermissions.includes(1) || patientFamilyPermissions.includes(2) || patientFamilyPermissions.includes(3) || patientFamilyPermissions.includes(4),
                    addEdit: patientFamilyPermissions.includes(2),
                    status: patientFamilyPermissions.includes(3),
                    approve: patientFamilyPermissions.includes(4),
                });
            }
            if (ROLE_PROVIDER in permissions) {
                const providerPermissions = permissions[ROLE_PROVIDER] ?? [];
                setProvider({
                    view: providerPermissions.includes(1) || providerPermissions.includes(2) || providerPermissions.includes(3),
                    addEdit: providerPermissions.includes(2),
                    status: providerPermissions.includes(3),
                });
            }
            if (ROLE_FACILITY in permissions) {
                const facilityPermissions = permissions[ROLE_FACILITY] ?? [];
                setFacility({
                    view: facilityPermissions.includes(1) || facilityPermissions.includes(2) || facilityPermissions.includes(3),
                    addEdit: facilityPermissions.includes(2),
                    status: facilityPermissions.includes(3),
                });
            }
            if (ROLE_REPORTS in permissions) {
                const reportPermissions = permissions[ROLE_REPORTS] ?? [];
                setReport({
                    view: reportPermissions.includes(1),
                });
            }
            if (ROLE_ROLE_AND_PERMISSIONS in permissions) {
                const rolesPermissions = permissions[ROLE_ROLE_AND_PERMISSIONS] ?? [];
                setRoles({
                    view: rolesPermissions.includes(1) || rolesPermissions.includes(2),
                    addEdit: rolesPermissions.includes(2),
                });
            }

            if (ROLE_COMPLETED_MESSAGES in permissions) {
                const completedMessagesPermissions = permissions[ROLE_COMPLETED_MESSAGES] ?? [];
                updatedMessage.viewCompletedmessages = completedMessagesPermissions.includes(5);
            }
            if (ROLE_CREATE_SEND_MESSAGES in permissions) {
                const createSendMessagesPermissions = permissions[ROLE_CREATE_SEND_MESSAGES] ?? [];
                updatedMessage.createSendMessages = createSendMessagesPermissions.includes(5);
            }
            if (ROLE_VIEW_INBOX_SENT_MESSAGES in permissions) {
                const viewInboxSentMessagesPermissions = permissions[ROLE_VIEW_INBOX_SENT_MESSAGES] ?? [];
                updatedMessage.viewInboxSentMessages = viewInboxSentMessagesPermissions.includes(5);

            }
            if (ROLE_VIEW_GENERAL_MESSAGES in permissions) {
                const viewGeneralMessagesPermissions = permissions[ROLE_VIEW_GENERAL_MESSAGES] ?? [];
                updatedMessage.viewGeneralMessages = viewGeneralMessagesPermissions.includes(5);

            }
            if (ROLE_SEND_GENERAL_MESSAGES in permissions) {
                const sendGeneralMessagesPermissions = permissions[ROLE_SEND_GENERAL_MESSAGES] ?? [];
                updatedMessage.sendGeneralMessages = sendGeneralMessagesPermissions.includes(5);

            }
            if (ROLE_SEND_ORDER in permissions) {
                const sendOrderPermissions = permissions[ROLE_SEND_ORDER] ?? [];
                updatedMessage.placeOrder = sendOrderPermissions.includes(5);

            }
            if (ROLE_VIEW_ORDER in permissions) {
                const viewOrderPermissions = permissions[ROLE_VIEW_ORDER] ?? [];
                updatedMessage.viewOrder = viewOrderPermissions.includes(5);

            }
            setMessage(updatedMessage);
        }
    }, [rolesSingleData]);

    useEffect(() => {
        if (Object.keys(viewRolePermission).length > 0) {
            setIsRolePresent(Object.keys(viewRolePermission).some((key) => rolesToCheck.includes(key)));
            setIsRoleMessagePresent(Object.keys(viewRolePermission).some((key) => meesageRolesToCheck.includes(key)));
            const updatedViewMessage = { ...viewMessage };
            const permissionsView = viewRolePermission;
            if (ROLE_CLIENT_ASSOCIATE_USER in permissionsView) {
                const associateViewPermissions = permissionsView[ROLE_CLIENT_ASSOCIATE_USER] ?? [];
                setViewAssociate({
                    view: associateViewPermissions.includes(1) || associateViewPermissions.includes(2) || associateViewPermissions.includes(3),
                    addEdit: associateViewPermissions.includes(2),
                    status: associateViewPermissions.includes(3),
                });
            }
            if (ROLE_NURSING_STAFF in permissionsView) {
                const nursingViewPermissions = permissionsView[ROLE_NURSING_STAFF] ?? [];
                setViewNursing({
                    view: nursingViewPermissions.includes(1) || nursingViewPermissions.includes(2) || nursingViewPermissions.includes(3) || nursingViewPermissions.includes(4),
                    addEdit: nursingViewPermissions.includes(2),
                    status: nursingViewPermissions.includes(3),
                    approve: nursingViewPermissions.includes(4),
                });
            }
            if (ROLE_PATIENTS_RELATIVES in permissionsView) {
                const patientFamilyViewPermissions = permissionsView[ROLE_PATIENTS_RELATIVES] ?? [];
                setViewPatientFamily({
                    view: patientFamilyViewPermissions.includes(1) || patientFamilyViewPermissions.includes(2) || patientFamilyViewPermissions.includes(3) || patientFamilyViewPermissions.includes(4),
                    addEdit: patientFamilyViewPermissions.includes(2),
                    status: patientFamilyViewPermissions.includes(3),
                    approve: patientFamilyViewPermissions.includes(4),
                });
            }
            if (ROLE_PROVIDER in permissionsView) {
                const providerViewPermissions = permissionsView[ROLE_PROVIDER] ?? [];
                setViewProvider({
                    view: providerViewPermissions.includes(1) || providerViewPermissions.includes(2) || providerViewPermissions.includes(3),
                    addEdit: providerViewPermissions.includes(2),
                    status: providerViewPermissions.includes(3),
                });
            }
            if (ROLE_FACILITY in permissionsView) {
                const facilityViewPermissions = permissionsView[ROLE_FACILITY] ?? [];
                setViewFacility({
                    view: facilityViewPermissions.includes(1) || facilityViewPermissions.includes(2) || facilityViewPermissions.includes(3),
                    addEdit: facilityViewPermissions.includes(2),
                    status: facilityViewPermissions.includes(3),
                });
            }
            if (ROLE_REPORTS in permissionsView) {
                const reportViewPermissions = permissionsView[ROLE_REPORTS] ?? [];
                setViewReport({
                    view: reportViewPermissions.includes(1),
                });
            }
            if (ROLE_ROLE_AND_PERMISSIONS in permissionsView) {
                const rolesViewPermissions = permissionsView[ROLE_ROLE_AND_PERMISSIONS] ?? [];
                setViewRoles({
                    view: rolesViewPermissions.includes(1) || rolesViewPermissions.includes(2),
                    addEdit: rolesViewPermissions.includes(2),
                });
            }

            if (ROLE_COMPLETED_MESSAGES in permissionsView) {
                const completedMessagesViewPermissions = permissionsView[ROLE_COMPLETED_MESSAGES] ?? [];
                updatedViewMessage.viewCompletedmessages = completedMessagesViewPermissions.includes(5);
            }
            if (ROLE_CREATE_SEND_MESSAGES in permissionsView) {
                const createSendMessagesViewPermissions = permissionsView[ROLE_CREATE_SEND_MESSAGES] ?? [];
                updatedViewMessage.createSendMessages = createSendMessagesViewPermissions.includes(5);
            }
            if (ROLE_VIEW_INBOX_SENT_MESSAGES in permissionsView) {
                const viewInboxSentMessagesViewPermissions = permissionsView[ROLE_VIEW_INBOX_SENT_MESSAGES] ?? [];
                updatedViewMessage.viewInboxSentMessages = viewInboxSentMessagesViewPermissions.includes(5);

            }
            if (ROLE_VIEW_GENERAL_MESSAGES in permissionsView) {
                const viewGeneralMessagesViewPermissions = permissionsView[ROLE_VIEW_GENERAL_MESSAGES] ?? [];
                updatedViewMessage.viewGeneralMessages = viewGeneralMessagesViewPermissions.includes(5);

            }
            if (ROLE_SEND_GENERAL_MESSAGES in permissionsView) {
                const sendGeneralMessagesViewPermissions = permissionsView[ROLE_SEND_GENERAL_MESSAGES] ?? [];
                updatedViewMessage.sendGeneralMessages = sendGeneralMessagesViewPermissions.includes(5);

            }
            if (ROLE_SEND_ORDER in permissionsView) {

                const sendOrderViewPermissions = permissionsView[ROLE_SEND_ORDER] ?? [];
                updatedViewMessage.placeOrder = sendOrderViewPermissions.includes(5);

            }
            if (ROLE_VIEW_ORDER in permissionsView) {
                const viewOrderViewPermissions = permissionsView[ROLE_VIEW_ORDER] ?? [];
                updatedViewMessage.viewOrder = viewOrderViewPermissions.includes(5);

            }

            setViewMessage(updatedViewMessage);
        }
    }, [viewRolePermission])
    useEffect(() => {
        if (Object.keys(disabledPermission).length > 0) {
            const updatedDisabledMessage = { ...disabledMessage };
            const permissionsDisabled = disabledPermission;
            if (ROLE_CLIENT_ASSOCIATE_USER in permissionsDisabled) {
                const associateDisabledPermissions = permissionsDisabled[ROLE_CLIENT_ASSOCIATE_USER] ?? [];
                setDisabledAssociate({
                    view: associateDisabledPermissions.includes(1) || associateDisabledPermissions.includes(2) || associateDisabledPermissions.includes(3),
                    addEdit: associateDisabledPermissions.includes(2),
                    status: associateDisabledPermissions.includes(3),
                });
            }
            if (ROLE_NURSING_STAFF in permissionsDisabled) {
                const nursingDisabledPermissions = permissionsDisabled[ROLE_NURSING_STAFF] ?? [];
                setDisabledNursing({
                    view: nursingDisabledPermissions.includes(1) || nursingDisabledPermissions.includes(2) || nursingDisabledPermissions.includes(3) || nursingDisabledPermissions.includes(4),
                    addEdit: nursingDisabledPermissions.includes(2),
                    status: nursingDisabledPermissions.includes(3),
                    approve: nursingDisabledPermissions.includes(4),
                });
            }
            if (ROLE_PATIENTS_RELATIVES in permissionsDisabled) {
                const patientFamilyDisabledPermissions = permissionsDisabled[ROLE_PATIENTS_RELATIVES] ?? [];
                setDisabledPatientFamily({
                    view: patientFamilyDisabledPermissions.includes(1) || patientFamilyDisabledPermissions.includes(2) || patientFamilyDisabledPermissions.includes(3) || patientFamilyDisabledPermissions.includes(4),
                    addEdit: patientFamilyDisabledPermissions.includes(2),
                    status: patientFamilyDisabledPermissions.includes(3),
                    approve: patientFamilyDisabledPermissions.includes(4),
                });
            }
            if (ROLE_PROVIDER in permissionsDisabled) {
                const providerDisabledPermissions = permissionsDisabled[ROLE_PROVIDER] ?? [];
                setDisabledProvider({
                    view: providerDisabledPermissions.includes(1) || providerDisabledPermissions.includes(2) || providerDisabledPermissions.includes(3),
                    addEdit: providerDisabledPermissions.includes(2),
                    status: providerDisabledPermissions.includes(3),
                });
            }
            if (ROLE_FACILITY in permissionsDisabled) {
                const facilityDisabledPermissions = permissionsDisabled[ROLE_FACILITY] ?? [];
                setDisabledFacility({
                    view: facilityDisabledPermissions.includes(1) || facilityDisabledPermissions.includes(2) || facilityDisabledPermissions.includes(3),
                    addEdit: facilityDisabledPermissions.includes(2),
                    status: facilityDisabledPermissions.includes(3),
                });
            }
            if (ROLE_REPORTS in permissionsDisabled) {
                const reportDisabledPermissions = permissionsDisabled[ROLE_REPORTS] ?? [];
                setDisabledReport({
                    view: reportDisabledPermissions.includes(1),
                });
            }
            if (ROLE_ROLE_AND_PERMISSIONS in permissionsDisabled) {
                const rolesDisabledPermissions = permissionsDisabled[ROLE_ROLE_AND_PERMISSIONS] ?? [];
                setDisabledRoles({
                    view: rolesDisabledPermissions.includes(1) || rolesDisabledPermissions.includes(2),
                    addEdit: rolesDisabledPermissions.includes(2),
                });
            }

            if (ROLE_COMPLETED_MESSAGES in permissionsDisabled) {
                const completedMessagesDisabledPermissions = permissionsDisabled[ROLE_COMPLETED_MESSAGES] ?? [];
                updatedDisabledMessage.viewCompletedmessages = completedMessagesDisabledPermissions.includes(5);
            }
            if (ROLE_CREATE_SEND_MESSAGES in permissionsDisabled) {
                const createSendMessagesDisabledPermissions = permissionsDisabled[ROLE_CREATE_SEND_MESSAGES] ?? [];
                updatedDisabledMessage.createSendMessages = createSendMessagesDisabledPermissions.includes(5);
            }
            if (ROLE_VIEW_INBOX_SENT_MESSAGES in permissionsDisabled) {
                const viewInboxSentMessagesDisabledPermissions = permissionsDisabled[ROLE_VIEW_INBOX_SENT_MESSAGES] ?? [];
                updatedDisabledMessage.viewInboxSentMessages = viewInboxSentMessagesDisabledPermissions.includes(5);

            }
            if (ROLE_VIEW_GENERAL_MESSAGES in permissionsDisabled) {
                const viewGeneralMessagesDisabledPermissions = permissionsDisabled[ROLE_VIEW_GENERAL_MESSAGES] ?? [];
                updatedDisabledMessage.viewGeneralMessages = viewGeneralMessagesDisabledPermissions.includes(5);

            }
            if (ROLE_SEND_GENERAL_MESSAGES in permissionsDisabled) {
                const sendGeneralMessagesDisabledPermissions = permissionsDisabled[ROLE_SEND_GENERAL_MESSAGES] ?? [];
                updatedDisabledMessage.sendGeneralMessages = sendGeneralMessagesDisabledPermissions.includes(5);

            }
            if (ROLE_SEND_ORDER in permissionsDisabled) {
                const sendOrderDisabledPermissions = permissionsDisabled[ROLE_SEND_ORDER] ?? [];
                updatedDisabledMessage.placeOrder = sendOrderDisabledPermissions.includes(5);

            }
            if (ROLE_VIEW_ORDER in permissionsDisabled) {
                const viewOrderDisabledPermissions = permissionsDisabled[ROLE_VIEW_ORDER] ?? [];
                updatedDisabledMessage.viewOrder = viewOrderDisabledPermissions.includes(5);

            }
            setDisabledMessage(updatedDisabledMessage);
        }
    }, [disabledPermission])




    //edit click on table
    const handleClickEdit = (data: any) => {
        setOpenCreateRole(true);
        setSelectedRole(data.id);
        const req: IRoleSigleState = {
            unid, token, client, role_id: data.id
        };
        dispatch(fetchSingleRoleData(req));
    }

    //View click on table
    const handleClickView = (data: any) => {
        setviewOnly(true);
        handleClickEdit(data);
    }




    const handleSaveRoles = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
        const permissions: IPermission[] = [];

        const addPermission = (module_name: string, category: number) => {
            permissions.push({ module_name, category });
        };

        const handlePermission = (condition: boolean, module_name: string, category: number) => {
            if (condition) {
                addPermission(module_name, category);
            }
        };

        handlePermission(associate.view, ROLE_CLIENT_ASSOCIATE_USER, 1);
        handlePermission(associate.addEdit, ROLE_CLIENT_ASSOCIATE_USER, 2);
        handlePermission(associate.status, ROLE_CLIENT_ASSOCIATE_USER, 3);

        handlePermission(nursing.view, ROLE_NURSING_STAFF, 1);
        handlePermission(nursing.addEdit, ROLE_NURSING_STAFF, 2);
        handlePermission(nursing.status, ROLE_NURSING_STAFF, 3);
        handlePermission(nursing.approve, ROLE_NURSING_STAFF, 4);

        handlePermission(patientFamily.view, ROLE_PATIENTS_RELATIVES, 1);
        handlePermission(patientFamily.addEdit, ROLE_PATIENTS_RELATIVES, 2);
        handlePermission(patientFamily.status, ROLE_PATIENTS_RELATIVES, 3);
        handlePermission(patientFamily.approve, ROLE_PATIENTS_RELATIVES, 4);

        handlePermission(provider.view, ROLE_PROVIDER, 1);
        handlePermission(provider.addEdit, ROLE_PROVIDER, 2);
        handlePermission(provider.status, ROLE_PROVIDER, 3);

        handlePermission(facility.view, ROLE_FACILITY, 1);
        handlePermission(facility.addEdit, ROLE_FACILITY, 2);
        handlePermission(facility.status, ROLE_FACILITY, 3);

        handlePermission(report.view, ROLE_REPORTS, 1);

        handlePermission(roles.view, ROLE_ROLE_AND_PERMISSIONS, 1);
        handlePermission(roles.addEdit, ROLE_ROLE_AND_PERMISSIONS, 2);

        handlePermission(message.viewCompletedmessages, ROLE_COMPLETED_MESSAGES, 5);
        handlePermission(message.createSendMessages, ROLE_CREATE_SEND_MESSAGES, 5);
        handlePermission(message.viewInboxSentMessages, ROLE_VIEW_INBOX_SENT_MESSAGES, 5);
        handlePermission(message.viewGeneralMessages, ROLE_VIEW_GENERAL_MESSAGES, 5);
        handlePermission(message.sendGeneralMessages, ROLE_SEND_GENERAL_MESSAGES, 5);
        handlePermission(message.placeOrder, ROLE_SEND_ORDER, 5);
        handlePermission(message.viewOrder, ROLE_VIEW_ORDER, 5);


        const req: IRolesAndPermissionsData = {
            unid,
            token,
            client,
            role: selectedRole,
            permissions: permissions // Assign the populated permissions array
        };

        dispatch(handleChangeRole(req));
        handleResetData();
    }


    const handleResetData = () => {
        setSelectedRole(0);
        setOpenCreateRole(false);
        setviewOnly(false);
        setAssociate({ view: false, addEdit: false, status: false });
        setFacility({ view: false, addEdit: false, status: false });
        setProvider({ view: false, addEdit: false, status: false });
        setNursing({ view: false, addEdit: false, status: false, approve: false });
        setPatientFamily({ view: false, addEdit: false, status: false, approve: false });
        setReport({ view: false });
        setRoles({ view: false, addEdit: false });
        setMessage({
            createSendMessages: false,
            viewInboxSentMessages: false,
            viewCompletedmessages: false,
            viewGeneralMessages: false,
            sendGeneralMessages: false,
            placeOrder: false,
            viewOrder: false,
        });

        setDisabledAssociate({ view: false, addEdit: false, status: false });
        setDisabledFacility({ view: false, addEdit: false, status: false });
        setDisabledProvider({ view: false, addEdit: false, status: false });
        setDisabledNursing({ view: false, addEdit: false, status: false, approve: false });
        setDisabledPatientFamily({ view: false, addEdit: false, status: false, approve: false });
        setDisabledReport({ view: false });
        setDisabledRoles({ view: false, addEdit: false });
        setDisabledMessage({
            createSendMessages: false,
            viewInboxSentMessages: false,
            viewCompletedmessages: false,
            viewGeneralMessages: false,
            sendGeneralMessages: false,
            placeOrder: false,
            viewOrder: false,
        });


        setIsRolePresent(false);
        setIsRoleMessagePresent(false);
        setViewAssociate({ view: false, addEdit: false, status: false });
        setViewFacility({ view: false, addEdit: false, status: false });
        setViewProvider({ view: false, addEdit: false, status: false });
        setViewNursing({ view: false, addEdit: false, status: false, approve: false });
        setViewPatientFamily({ view: false, addEdit: false, status: false, approve: false });
        setViewReport({ view: false });
        setViewRoles({ view: false, addEdit: false });
        setViewMessage({
            createSendMessages: false,
            viewInboxSentMessages: false,
            viewCompletedmessages: false,
            viewGeneralMessages: false,
            sendGeneralMessages: false,
            placeOrder: false,
            viewOrder: false,
        });

    }


    return (
        <div className='w-full'>
            <div className="flex justify-between items-center  pt-4 pb-[45px] pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>Roles And Permissions</h1>
                </div>
                <div>
                    {/* <ButtonType type='iconLeft' buttonLabel='Add Role' icon={plusIcon} onClick={() => setOpenCreateRole(true)} /> */}
                </div>
            </div>
            <div className='pl-12 pr-8 '>
                <Table
                    parentPage={ROLE_ROLE_AND_PERMISSIONS}
                    setPermission={true}
                    titles={RoleListHeader}
                    data={rolesListData}
                    sortedFieldName={sortField}
                    currentPage={1}
                    numRowsPerPage={20}
                    handleSort={(sortField: string) => setSortField(sortField)}
                    onClickEdit={(obj: any) => handleClickEdit(obj)}
                    onClickView={(obj: any) => handleClickView(obj)}
                />
                {/* <Paginations /> */}
            </div>
            <Modal
                open={openCreateRole}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={commonModelStyle} className='focus:outline-none w-full max-w-[936px]'>
                    <ModalHeader title={"Roles And Permissions"} onClick={() => handleResetData()} />

                    <form className='max-h-[calc(100vh-200px)] custom-scrollBar' onSubmit={(e) => handleSaveRoles(e)}>
                        <div className="px-9">

                            <div className="w-full my-9">
                                <table className='w-full table-role'>
                                    {isRolePresent && <thead>
                                        <tr>
                                            <td></td>
                                            <td>View</td>
                                            <td>Add/Edit</td>
                                            <td>Deactivate</td>
                                            <td>Approve</td>
                                        </tr>
                                    </thead>}
                                    <tbody>
                                        {isRolePresent && <>
                                            {(viewAssociate.addEdit || viewAssociate.status || viewAssociate.view) &&
                                                <tr>
                                                    <td>Associate users</td>
                                                    <td>
                                                        {viewAssociate.view && <input type="checkbox"
                                                            onClick={() => setAssociate({ ...associate, view: !associate.view })}
                                                            checked={associate.view}
                                                            disabled={associate.addEdit || associate.status || disabledAssociate.view || viewOnly}
                                                            name="" id="" />}
                                                    </td>

                                                    <td>
                                                        {viewAssociate.addEdit && <input type="checkbox"
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                setAssociate((prevState) => ({
                                                                    ...prevState,
                                                                    addEdit: isChecked,
                                                                    view: isChecked ? true : prevState.view,
                                                                }));
                                                            }}
                                                            disabled={disabledAssociate.addEdit || viewOnly}
                                                            checked={associate.addEdit}
                                                            name="" id="" />}
                                                    </td>
                                                    <td>
                                                        {viewAssociate.status && <input type="checkbox"

                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                setAssociate((prevState) => ({
                                                                    ...prevState,
                                                                    status: isChecked,
                                                                    view: isChecked ? true : prevState.view,
                                                                }));
                                                            }}
                                                            checked={associate.status}
                                                            disabled={disabledAssociate.status || viewOnly}
                                                            name="" id="" />}
                                                    </td>
                                                    <td></td>
                                                </tr>}
                                            {(viewFacility.addEdit || viewFacility.status || viewFacility.view) &&
                                                <tr>
                                                    <td>Facility</td>
                                                    <td>{viewFacility.view &&
                                                        <input type="checkbox"
                                                            onClick={() => setFacility({ ...facility, view: !facility.view })}
                                                            disabled={facility.addEdit || facility.status || disabledFacility.view || viewOnly}
                                                            checked={facility.view}
                                                            name="" id="" />}
                                                    </td>
                                                    <td>{viewFacility.addEdit &&
                                                        <input type="checkbox"
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                setFacility((prevState) => ({
                                                                    ...prevState,
                                                                    addEdit: isChecked,
                                                                    view: isChecked ? true : prevState.view,
                                                                }));
                                                            }}
                                                            checked={facility.addEdit}
                                                            disabled={disabledFacility.addEdit || viewOnly}
                                                            name="" id="" />}</td>
                                                    <td>{viewFacility.status &&
                                                        <input type="checkbox"
                                                            onChange={(e) => {
                                                                const isChecked = e.target.checked;
                                                                setFacility((prevState) => ({
                                                                    ...prevState,
                                                                    status: isChecked,
                                                                    view: isChecked ? true : prevState.view,
                                                                }));
                                                            }}
                                                            checked={facility.status}
                                                            disabled={disabledFacility.status || viewOnly}
                                                            name="" id="" />}</td>
                                                    <td></td>
                                                </tr>}
                                            {(viewProvider.addEdit || viewProvider.status || viewProvider.view) &&
                                                <tr>
                                                    <td>Provider</td>
                                                    <td>{viewProvider.view && <input type="checkbox"
                                                        onClick={() => setProvider({ ...provider, view: !provider.view })}
                                                        checked={provider.view}
                                                        disabled={provider.addEdit || provider.status || disabledProvider.view || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td>{viewProvider.addEdit && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setProvider((prevState) => ({
                                                                ...prevState,
                                                                addEdit: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={provider.addEdit}
                                                        disabled={disabledProvider.addEdit || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td>{viewProvider.status && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setProvider((prevState) => ({
                                                                ...prevState,
                                                                status: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={provider.status}
                                                        disabled={disabledProvider.status || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td></td>
                                                </tr>}
                                            {(viewNursing.addEdit || viewNursing.status || viewNursing.view || viewNursing.approve) &&
                                                <tr>
                                                    <td>Facility Staff</td>
                                                    <td>{viewNursing.view && <input type="checkbox"
                                                        onClick={() => setNursing({ ...nursing, view: !nursing.view })}
                                                        disabled={nursing.addEdit || nursing.approve || nursing.status || disabledNursing.view || viewOnly}
                                                        checked={nursing.view}
                                                        name="" id="" />}</td>
                                                    <td>{viewNursing.addEdit && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setNursing((prevState) => ({
                                                                ...prevState,
                                                                addEdit: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={nursing.addEdit}
                                                        disabled={disabledNursing.addEdit || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td>{viewNursing.status && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setNursing((prevState) => ({
                                                                ...prevState,
                                                                status: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={nursing.status}
                                                        disabled={disabledNursing.status || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td>{viewNursing.approve && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setNursing((prevState) => ({
                                                                ...prevState,
                                                                approve: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={nursing.approve}
                                                        disabled={disabledNursing.approve || viewOnly}
                                                        name="" id="" />}</td>
                                                </tr>}
                                            {(viewPatientFamily.addEdit || viewPatientFamily.status || viewPatientFamily.view || viewPatientFamily.approve) &&
                                                <tr>
                                                    <td>Patient/Family Member</td>
                                                    <td>{viewPatientFamily.view && <input type="checkbox"
                                                        onClick={() => setPatientFamily({ ...patientFamily, view: !patientFamily.view })}
                                                        disabled={patientFamily.addEdit || patientFamily.approve || patientFamily.status || disabledPatientFamily.view || viewOnly}
                                                        checked={patientFamily.view}
                                                        name="" id="" />}</td>
                                                    <td>{viewPatientFamily.addEdit && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setPatientFamily((prevState) => ({
                                                                ...prevState,
                                                                addEdit: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={patientFamily.addEdit}
                                                        disabled={disabledPatientFamily.addEdit || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td>{viewPatientFamily.status && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setPatientFamily((prevState) => ({
                                                                ...prevState,
                                                                status: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={patientFamily.status}
                                                        disabled={disabledPatientFamily.status || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td>{viewPatientFamily.approve && <input type="checkbox"
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setPatientFamily((prevState) => ({
                                                                ...prevState,
                                                                approve: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={patientFamily.approve}
                                                        disabled={disabledPatientFamily.approve || viewOnly}
                                                        name="" id="" />}</td>
                                                </tr>}
                                            {(viewReport.view) &&
                                                <tr>
                                                    <td>Reports</td>
                                                    <td><input
                                                        type="checkbox"
                                                        onClick={() => setReport({ ...report, view: !report.view })}
                                                        checked={report.view}
                                                        disabled={disabledReport.view || viewOnly}
                                                        name="" id="" /></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>}
                                            {(viewRoles.addEdit || viewRoles.view) &&
                                                <tr>
                                                    <td>Roles and Permissions</td>
                                                    <td>{viewRoles.view && <input type="checkbox"
                                                        onClick={() => setRoles({ ...roles, view: !roles.view })}
                                                        disabled={roles.addEdit || disabledRoles.view || viewOnly}
                                                        checked={roles.view}
                                                        name="" id="" />}</td>
                                                    <td>{viewRoles.addEdit && <input type="checkbox"
                                                        onClick={() => setRoles({ ...roles, addEdit: !roles.addEdit })}
                                                        onChange={(e) => {
                                                            const isChecked = e.target.checked;
                                                            setRoles((prevState) => ({
                                                                ...prevState,
                                                                addEdit: isChecked,
                                                                view: isChecked ? true : prevState.view,
                                                            }));
                                                        }}
                                                        checked={roles.addEdit}
                                                        disabled={disabledRoles.addEdit || viewOnly}
                                                        name="" id="" />}</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>}
                                        </>}
                                        {isRolePresent && <tr>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                            <td>&nbsp;</td>
                                        </tr>}
                                        {isRoleMessagePresent && <tr>
                                            <td className='text-lg font-medium '>Messages</td>
                                            <td className='font-medium'>Allow</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>}
                                        {isRoleMessagePresent && <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>}
                                        {viewMessage.createSendMessages &&
                                            <tr>
                                                <td>Create/Send Messages</td>
                                                <td>
                                                    <input type="checkbox"
                                                        onClick={() => setMessage({ ...message, createSendMessages: !message.createSendMessages })}
                                                        checked={message.createSendMessages}
                                                        disabled={disabledMessage.createSendMessages || viewOnly}
                                                        name="" id="" /></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                        {viewMessage.viewInboxSentMessages &&
                                            <tr>
                                                <td>View Inbox/Sent Messages</td>
                                                <td><input type="checkbox"
                                                    onClick={() => setMessage({ ...message, viewInboxSentMessages: !message.viewInboxSentMessages })}
                                                    checked={message.viewInboxSentMessages}
                                                    disabled={disabledMessage.viewInboxSentMessages || viewOnly}
                                                    name="" id="" /></td>

                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                        {(viewMessage.viewCompletedmessages && roleName !== ROLE_PATIENTS_NAME) &&
                                            <tr>
                                                <td>View Completed messages</td>
                                                <td><input type="checkbox"
                                                    onClick={() => setMessage({ ...message, viewCompletedmessages: !message.viewCompletedmessages })}
                                                    checked={message.viewCompletedmessages}
                                                    disabled={disabledMessage.viewCompletedmessages || viewOnly}
                                                    name="" id="" /></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                        {viewMessage.viewGeneralMessages &&
                                            <tr>
                                                <td>View General Messages</td>
                                                <td><input type="checkbox" onClick={() => setMessage({ ...message, viewGeneralMessages: !message.viewGeneralMessages })}
                                                    checked={true}
                                                    // checked={message.viewGeneralMessages}
                                                    disabled={true}
                                                    // disabled={disabledMessage.viewGeneralMessages || viewOnly}
                                                    name="" id="" /></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                        {viewMessage.sendGeneralMessages &&
                                            <tr>
                                                <td>Send General Messages</td>
                                                <td><input type="checkbox" onClick={() => setMessage({ ...message, sendGeneralMessages: !message.sendGeneralMessages })}
                                                    checked={true}
                                                    // checked={message.sendGeneralMessages}
                                                    disabled={true}
                                                    // disabled={disabledMessage.sendGeneralMessages || viewOnly}
                                                    name="" id="" /></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                        {viewMessage.placeOrder &&
                                            <tr>
                                                <td>Place Order</td>
                                                <td><input type="checkbox"
                                                    onClick={() => setMessage({ ...message, placeOrder: !message.placeOrder })} checked={message.placeOrder}
                                                    disabled={disabledMessage.placeOrder || viewOnly}
                                                    name="" id="" /></td>
                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}
                                        {viewMessage.viewOrder &&
                                            <tr>
                                                <td>View Order</td>
                                                <td><input type="checkbox"
                                                    onClick={() => setMessage({ ...message, viewOrder: !message.viewOrder })}
                                                    checked={message.viewOrder}
                                                    disabled={disabledMessage.viewOrder || viewOnly}
                                                    name="" id="" /></td>

                                                <td></td>
                                                <td></td>
                                                <td></td>
                                            </tr>}

                                    </tbody>
                                </table>
                            </div>


                            {!viewOnly && <>
                                <hr className='border-[var(--color-E0E0E0)] ' />
                                <div className="flex gap-4 justify-end items-end my-9">
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Cancel'
                                        onClick={() => handleResetData()} className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Save'
                                        buttonType='submit'
                                        className='px-12 bg-[var(--color-0048D4)]' />
                                </div></>}
                        </div>
                    </form>
                </Box>
            </Modal >
        </div >
    )
}

export default RolesAndPermission
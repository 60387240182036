import React, { useEffect, useState } from 'react';
import plusIcon from '../../assets/images/plusIcon.svg'
import { Box, Button, Modal } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ButtonType from '../../components/button/ButtonType';
import Table from '../../components/table';
import { AutoReplyHeader } from '../../components/table/tableHeaders';
import { ROLE_AUTO_REPLY } from '../../config/permissions';
import { useAppDispatch, useAppSelector } from '../../helpers/hook';
import { AutoReplyListSlices } from './autoReplySlice';
import { addHoliday, deleteHolidayData, fetchAutoReplyList, updateHolidayData } from '../../services/autoReplyServices';
import { loginPageSlices } from '../loginPage/loginPageSlice';
import { IAutoReplyListState } from '../../models/autoReplyInterfaces';
import ModalHeader from '../../components/modal/ModalHeader';
import { commonModelStyle } from '../../config/constants';
import TextField from '../../components/inputFeild/TextFeild';
import ConfirmationMessage from '../../components/notification/ConfirmationMessage';
import { message } from '../../utilities/locals/messages';


const AutoReply = () => {
  const dispatch = useAppDispatch();
  const { token, unid } =
    useAppSelector(loginPageSlices);
  const { autoReplyListData, loading } = useAppSelector(AutoReplyListSlices);

  const initialAutoReplyState = {
    unid,
    token,
    id: 0,
    title: '', date: '',
  }

  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [newAutoReply, setNewAutoReply] = useState<IAutoReplyListState>(initialAutoReplyState);
  const [modalOpen, setModalOpen] = useState({ "dataModal": false, "deleteConfirmModal": false });

  useEffect(() => {
    fetchAutoReplyListData(year);
  }, [])

  useEffect(() => {
    if (loading === "success") {
      fetchAutoReplyListData(year);
    } 
  }, [loading])

  const fetchAutoReplyListData = (year: number) => {
    const req: IAutoReplyListState = {
      unid,
      token, year
    }
    dispatch(fetchAutoReplyList(req))
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewAutoReply((prev) => ({ ...prev, [name]: value }));
  };


  // Handle form submission (add/update)
  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { id, ...holidayData } = newAutoReply;
    id === 0 ? dispatch(addHoliday(holidayData)) : dispatch(updateHolidayData(newAutoReply));
    resetData();
  };

  //edit click on table
  const handleClickEdit = (data: any) => {
    setNewAutoReply(preState => ({ ...preState, id: data.id, title: data.title, date: data.date }));
    setModalOpen(prevState => ({ ...prevState, dataModal: true }));
  }

  // Delete holiday
  const handleClickDelete = (data: any) => {
    setNewAutoReply(preState => ({ ...preState, id: data.id, title: data.title, date: data.date }));
    setModalOpen(prevState => ({ ...prevState, deleteConfirmModal: true }));
  }

  const handleConfirmDelete = () => {
    const { id } = newAutoReply;
    dispatch(deleteHolidayData({ id, unid, token }));
    resetData();
  }

  // Change year
  const changeYear = (direction: 'prev' | 'next') => {
    setYear(direction === 'prev' ? year - 1 : year + 1);
    fetchAutoReplyListData(direction === 'prev' ? year - 1 : year + 1);
  };

  //reset data
  const resetData = () => {
    setNewAutoReply(initialAutoReplyState);
    setModalOpen({ "dataModal": false, "deleteConfirmModal": false });
  }

  return (
    <div className="w-full">
      {/* Header Section */}
      <div className="flex justify-between items-center pt-4 pb-8 pl-12 pr-8">
        <h1 className="text-2xl text-[var(--color-212121)]">Auto-reply Settings - Holidays</h1>
      </div>

      {/* Year Selection */}


      <div className='flex justify-between items-center   pb-9 pl-12 pr-8'>

        <div className=" flex items-center">

          <p className="tracking-[0.05px] text-[var(--color-424242)] pr-4 ">Holidays In:</p>
          <div className="flex gap-4 items-center">
            <Button variant="outlined" className='p-[5px] min-w-fit' onClick={() => changeYear('prev')}> <ArrowBackIosNewRoundedIcon fontSize='small' /></Button>
            <p className="text-xl font-bold">{year}</p>
            <Button variant="outlined" className='p-[5px] min-w-fit' onClick={() => changeYear('next')}> <ArrowForwardIosRoundedIcon fontSize='small' /></Button>

          </div>
          
        </div>

        <ButtonType
          type='iconLeft'
          buttonLabel='Add Holiday'
          icon={plusIcon}
          onClick={() => setModalOpen(prevState => ({ ...prevState, dataModal: true }))}
        />

      </div>

      {/* Holiday Table */}
      <div className="pl-12 pr-8">

        <Table
          titles={AutoReplyHeader}
          data={autoReplyListData}
          parentPage={ROLE_AUTO_REPLY}
          setPermission={false}
          onClickEdit={(data: any) => handleClickEdit(data)}
          onClickDelete={(data: any) => handleClickDelete(data)}
        />

      </div>

      <Modal
        open={modalOpen.dataModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={commonModelStyle} className='focus:outline-none w-full max-w-[936px]'>
          <ModalHeader title={newAutoReply.id === 0 ? 'Add New Holiday' : 'Update Holiday'} onClick={() => resetData()} />

          <form className='max-h-[calc(100vh-200px)] custom-scrollBar' typeof='submit' onSubmit={(e) => handleSubmitForm(e)}>
            <div className="px-9">



              <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">

                <TextField
                  label="Holiday Date"
                  name="date"
                  required={true}
                  value={newAutoReply.date}
                  type="date"
                  onChange={handleChange}
                />

                <TextField
                  label="Remark"
                  name="title"
                  required={true}
                  value={newAutoReply.title}
                  placeholder="Enter a remark"
                  onChange={handleChange}
                />

              </div>

              <hr className='border-[var(--color-E0E0E0)] ' />
              <div className="flex gap-4 justify-end items-end my-9">
                <ButtonType
                  type='webCardbtn'
                  buttonLabel='Cancel'
                  onClick={() => resetData()}
                  className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                <ButtonType
                  type='webCardbtn'
                  buttonLabel='Save'
                  buttonType='submit'
                  className='px-12 bg-[var(--color-0048D4)]' />
              </div>

            </div>
          </form>
        </Box>
      </Modal>


      <ConfirmationMessage
        title={message.CFPCareConnect}
        description={`Are you sure you want to remove this holiday?`}
        open={modalOpen.deleteConfirmModal}
        textType={false}
        type={'danger'}
        buttonTwoOnClick={() => resetData()}
        buttonOneOnClick={() => handleConfirmDelete()}
        buttonLabelOne={'Delete'}
        buttonLabelTwo='Cancel'
      />
    </div>
  );
};

export default AutoReply;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAutoReplyListState } from "../models/autoReplyInterfaces";
import { URL_ADD_HOLIDAY, URL_DELETE_HOLIDAY, URL_FETCH_AUTO_REPLY, URL_UPDATE_HOLIDAY } from "../config/apiUrls";
import { deleteApi, get, post, put } from "./apiService";


// to fetch all fetchAutoReplyList
export const fetchAutoReplyList = createAsyncThunk(
  'autoReplyListSlice/fetchAutoReplyList',
  async (form: IAutoReplyListState, thunkAPI) => {
    try {
      const { year, ...body } = form;
      const url = URL_FETCH_AUTO_REPLY+ '?year=' + year
      const response = get(url, body);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to add Holiday
export const addHoliday = createAsyncThunk(
  'autoReplyListSlice/addHoliday',
  async (form: IAutoReplyListState, thunkAPI) => {
    try {
      const response = post(URL_ADD_HOLIDAY, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update updateHolidayData user
export const updateHolidayData = createAsyncThunk(
  'autoReplyListSlice/updateHolidayData',
  async (form: IAutoReplyListState, thunkAPI) => {
    try {
      const response = put(URL_UPDATE_HOLIDAY, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

//to update updateHolidayData user
export const deleteHolidayData = createAsyncThunk(
  'autoReplyListSlice/deleteHolidayData',
  async (form: IAutoReplyListState, thunkAPI) => {
    try {
      const response = deleteApi(URL_DELETE_HOLIDAY, form);
      return (await response).data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
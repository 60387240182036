import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box, Modal } from '@mui/material'
import SignatureCanvas from 'react-signature-canvas';
import plusIcon from '../../assets/images/plusIcon.svg'
import ButtonType from '../../components/button/ButtonType'
import Table from '../../components/table'
import TextField from '../../components/inputFeild/TextFeild'
import PhoneInputFeild from '../../components/inputFeild/PhoneInputFeild'
import { providerListHeader } from '../../components/table/tableHeaders'
import SelectField from '../../components/inputFeild/SelectField'
import { IProviderData, IProviderState, IProviderStatus } from '../../models/providerListInterfaces'
import { useAppDispatch, useAppSelector } from '../../helpers/hook'
import { loginPageSlices } from '../loginPage/loginPageSlice'
import { CommonSlices } from '../common/commonSlice'
import { addProviderData, addProviderImageInput, addProviderSignInput, editProviderData, fetchProviderList, removeProviderImageInput, removeProviderSignInput, resentVerificationProviderMail, updateProviderData, updateProviderUserStatus } from '../../services/providerServices'
import ImageInput from '../../components/inputFeild/ImageInput'
import MultiSelectField from '../../components/inputFeild/MultiSelectField'
import ModalHeader from '../../components/modal/ModalHeader'
import { setNotification } from '../../components/notification/ToastNotification'
import { message } from '../../utilities/locals/messages'
import ConfirmationMessage from '../../components/notification/ConfirmationMessage'
import { ProviderListSlices } from './providerListSlice'
import { DEFAULT_COUNTRY_US, LOGO_UPLOAD_OTHERS, LOGO_UPLOAD_PROFILE_PIC, LOGO_UPLOAD_SIGN, PROVIDER, P_ACT_ADD_EDIT, ROLE_PROVIDER, USER_ROLE_CLIENT_ADMIN, USER_ROLE_CLIENT_ASSOCIATE, USER_ROLE_PROVIDER, useHasActionPermission, useHasViewPermission } from '../../config/permissions'
import { IImageInput, IRemoveImage, IResentEmail } from '../../models/commonInterfaces'
import Paginations from '../../components/Pagination';
import { commonModelStyle } from '../../config/constants';




const ProviderList = () => {
    const { token, unid, client, logedUsername, loginUserRole } = useAppSelector(loginPageSlices);
    const { navFacility, facilityData } = useAppSelector(CommonSlices);
    const { providerUserListData, paginationTotalCount, providerUser, loading, imageId, uploadedImg, uploadedSignImg, imageSignId } = useAppSelector(ProviderListSlices);
    const dispatch = useAppDispatch();
    //PERMISSION
    const checkViewPermission = useHasViewPermission(ROLE_PROVIDER);
    const hasResendEmailPermission = (loginUserRole === USER_ROLE_CLIENT_ADMIN || loginUserRole === USER_ROLE_CLIENT_ASSOCIATE);

    //Paginaton
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;

    const [firstName, setFirstName] = useState<string>('');
    const [middleName, setMiddleName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [profileImage, setProfileImage] = useState<string>('');
    const [imageProId, setImageProId] = useState<number | null>(null);
    const [gender, setGender] = useState<string>('');
    const [facility, setFacility] = useState<number[]>([]);
    const [email, setEmail] = useState<string>('');
    const [resendEmail, setresendEmail] = useState<boolean>(false);
    const [userEmailId, setUserEmailId] = useState<number>();

    const [emailNotification, setEmailNotification] = useState<string>('2');

    const [mobile, setMobile] = useState('');
    const [alternativeMobile, setAlternativeMobile] = useState('');

    const [signImage, setsignImage] = useState<string>('');
    const [signImageId, setSignImageId] = useState<number | null>(null);
    const [sign, setSign] = useState<any>();


    const [sortField, setSortField] = useState("full_name");
    const [openCreateProvider, setOpenCreateProvider] = useState(false);
    const [userId, setUserId] = useState<string>('');
    const [showStatusConfirmation, setShowStatusConfirmation] = useState<boolean>(false);
    const [viewOnly, setviewOnly] = useState<boolean>(false);
    const [storeStatus, setStoreStatus] = useState<boolean>(false);
    const [storeStatusData, setStoreStatusData] = useState<any>();
    const [addProvider, setAddProvider] = useState<boolean>(true);


    //initial fetching the data to display item on table
    useEffect(() => {
        if (checkViewPermission && (navFacility && navFacility.length > 0)) {
            const req: IProviderState = {
                unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: PROVIDER, page: currentPage
            };
            dispatch(fetchProviderList(req));

        }
    }, [navFacility])

    //use to reload the table data when dispatch oru other functionality is applied
    useEffect(() => {
        if (loading === 'success' && checkViewPermission) {
            const req: IProviderState = {
                unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: PROVIDER, page: currentPage
            };
            dispatch(fetchProviderList(req));
            if (openCreateProvider) {
                handleProviderReset();
            }
        }
    }, [loading])


    //to handle edit provider 
    useEffect(() => {
        if (providerUser.length > 0) {
            const userDetails = providerUser[0]
            setAddProvider(false);
            setFirstName(userDetails.first_name);
            setMiddleName(userDetails.middle_name);
            setLastName(userDetails.last_name);
            setGender(userDetails.gender.toString());
            setFacility(userDetails.facility);
            setEmail(userDetails.email);
            setresendEmail(userDetails.email_verified);
            setUserEmailId(userDetails.user_id);
            setMobile(userDetails.mobile);
            setAlternativeMobile(userDetails.alternate_mobile);
            setProfileImage(userDetails.profile_pic_url);
            setImageProId(userDetails.profile_pic);
            setsignImage(userDetails.sign_url);
            setSignImageId(userDetails.sign);
            setEmailNotification(userDetails.notifications ? userDetails.notifications.toString() : '2')
        }
    }, [providerUser]);

    useEffect(() => {
        setImageProId(imageId);
    }, [imageId]);
    useEffect(() => {
        setProfileImage(uploadedImg);
    }, [uploadedImg]);
    useEffect(() => {
        setsignImage(uploadedSignImg);
    }, [uploadedSignImg])
    useEffect(() => {
        setSignImageId(imageSignId);
    }, [imageSignId])

    //save button function on modal
    const handleSaveProviderData = (e: any) => {
        e.preventDefault();
        if (firstName.length > 0 && lastName.length > 0 && facility.length > 0 && email.length > 0) {
            if (mobile.length < 2 || mobile.length > 6) {

                const req: IProviderData = {
                    unid,
                    token,
                    uuid: userId,
                    client: client,
                    facility: facility,
                    gender: Number(gender),
                    profile_pic: imageProId,
                    sign: signImage === '' ? null : signImageId,
                    sign_base: signImage === '' ? (loginUserRole === USER_ROLE_PROVIDER && sign.isEmpty()) ? "" : loginUserRole === USER_ROLE_PROVIDER ? sign.getTrimmedCanvas().toDataURL('image/png') : '' : '',
                    notifications: Number(emailNotification),
                    user_info: {
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: email,
                        mobile: mobile,
                        alternate_mobile: alternativeMobile
                    },
                }
                if (addProvider) {
                    //for add client  
                    dispatch(addProviderData(req));
                } else {
                    //for update client
                    dispatch(updateProviderData(req));
                }
            } else {
                setNotification(message.CFPCareConnect, message.invalidMobile, false, 'failed', 'handleSaveProviderData');
            }
        } else {
            setNotification(message.CFPCareConnect, message.fillOutAllMandatoryFields, false, 'failed', 'handleSaveProviderData');
        }
    }



    //edit click on table
    const handleClickEdit = (data: any) => {
        const uuid = data.uuid;
        setUserId(uuid);
        const req: IProviderState = {
            unid, token, uuid
        };
        dispatch(editProviderData(req));
        setOpenCreateProvider(true);
    }
    //Resent email 
    const onResendEmail = (data: any) => {
        const req: IResentEmail = {
            unid, token, user_id: data.user_id, user_email: data.email

        };
        dispatch(resentVerificationProviderMail(req));
    }
    //View click on table
    const handleClickView = (data: any) => {
        setviewOnly(true);
        handleClickEdit(data);
    }
    //function handles Deactivate and Activation of client 
    const handleConfiramation = () => {
        const uuid = storeStatusData.uuid
        const req: IProviderStatus = {
            unid, token, uuid, action: storeStatusData.active ? 1 : 2
        };
        dispatch(updateProviderUserStatus(req));
        setShowStatusConfirmation(false);
    }

    //confirmation popup 
    const handleClickStatus = (data: any) => {
        setShowStatusConfirmation(true);
        setStoreStatusData(data);
        setStoreStatus(data.active)
    }

    //for reset all value in modal
    const handleProviderReset = () => {
        setFirstName('');
        setMiddleName('');
        setLastName('');
        setGender('');
        setFacility([]);
        setEmail('');
        setEmailNotification('2');
        setresendEmail(false);
        setMobile('');
        setAlternativeMobile('');
        setProfileImage("");
        setsignImage("");
        setOpenCreateProvider(!openCreateProvider);
        setviewOnly(false);
        setAddProvider(true);
        setUserId('');
        setSignImageId(null);
        setImageProId(null);
    }

    //for add profile image
    const handleAddImage = (selectedFile: File) => {
        const req: IImageInput = {
            token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_PROFILE_PIC
        }
        dispatch(addProviderImageInput(req));
    }
    //remove profile image
    const handleRemoveImage = () => {
        if (imageProId && imageProId !== null) {

            const req: IRemoveImage = {
                token, unid, fid: imageProId
            }
            dispatch(removeProviderImageInput(req));
        }
    }

    //for add profile image
    const handleAddSign = (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0];

        if (selectedFile) {
            const req: IImageInput = {
                token, unid, image: selectedFile, type: LOGO_UPLOAD_OTHERS, image_type: LOGO_UPLOAD_SIGN
            }
            dispatch(addProviderSignInput(req));
        }
        e.target.value = '';
    }

    //remove sign image
    const handleRemoveSign = () => {
        if (signImage === '' && loginUserRole === USER_ROLE_PROVIDER) {
            sign.clear();
        }
        setsignImage('');
        if (signImageId !== null) {
            const req: IRemoveImage = {
                token, unid, fid: signImageId
            }
            dispatch(removeProviderSignInput(req));

        }
        setSignImageId(null);
    }

    //To handle filiter
    const handleProviderList = (value: any, field: string) => {
    
            const req: IProviderState = {
                unid,
                token,
                facility: navFacility,
                client: client,
                user: logedUsername,
                group: loginUserRole,
                user_type: PROVIDER,
                page: (field === 'pagination') ? value : currentPage,
                sort: (field === 'sort_provider') ? value : sortField,
            };
            dispatch(fetchProviderList(req));
   
    }

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        if (checkViewPermission) {
            handleProviderList(page, 'pagination');
            // const req: IProviderState = {
            //     unid, token, facility: navFacility, client: client, user: logedUsername, group: loginUserRole, user_type: PROVIDER, page: page
            // };
            // dispatch(fetchProviderList(req));
        }
    };
    return (
        <div className='w-full'>
            <div className="flex justify-between items-center  pt-4 pb-[45px] pl-12 pr-8">
                <div>
                    <h1 className='text-2xl text-[var(--color-212121)] '>Providers</h1>
                    {/* <span className='text-sm text-[var(--color-424242)]'>Allegro of Winter Park</span> */}

                </div>
                <div>
                    {useHasActionPermission(ROLE_PROVIDER, P_ACT_ADD_EDIT) && <ButtonType type='iconLeft' buttonLabel='Add Provider' icon={plusIcon} onClick={() => handleProviderReset()} />}
                </div>
            </div>
            <div className='pl-12 pr-8 '>
                <Table
                    parentPage={ROLE_PROVIDER}
                    setPermission={true}
                    titles={providerListHeader}
                    data={providerUserListData}
                    currentPage={currentPage}
                    numRowsPerPage={itemsPerPage}
                    hasResendEmailPermission={hasResendEmailPermission}
                    sortedFieldName={sortField}
                    handleSort={(sortField: string) => {
                        setSortField(sortField);
                        handleProviderList(sortField, 'sort_provider');
                    }}
                    onClickEdit={(uuid: any) => handleClickEdit(uuid)}
                    onClickView={(uuid: any) => handleClickView(uuid)}
                    onClickStatus={(obj: any) => handleClickStatus(obj)}
                    onResendEmail={(obj: any) => onResendEmail(obj)}
                    className={Math.ceil(paginationTotalCount / itemsPerPage) > 1 ? '' : ' mb-28 '}
                />
            </div>
            <Paginations
                totalItems={paginationTotalCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
            />
            <Modal
                open={openCreateProvider}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={commonModelStyle} className='focus:outline-none w-full max-w-[936px]'>
                    <ModalHeader title={addProvider ? 'Add Provider User' : viewOnly ? 'Provider User' : 'Update Provider User'} onClick={() => handleProviderReset()} />

                    <form className='max-h-[calc(100vh-200px)] custom-scrollBar' typeof='submit' onSubmit={(e) => handleSaveProviderData(e)}>
                        <div className="px-9">

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="flex flex-col gap-9 w-full">
                                    <TextField
                                        label=' First Name'
                                        required={true}
                                        value={firstName}
                                        disabled={viewOnly}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        placeholder=' First Name' />
                                    <TextField
                                        label=' Middle Name'
                                        value={middleName}
                                        disabled={viewOnly}
                                        onChange={(e) => setMiddleName(e.target.value)}
                                        placeholder=' Middle Name' />
                                </div>
                                <ImageInput label='Profile Picture' profileImage={profileImage} handleAddImage={handleAddImage} handleRemoveImage={handleRemoveImage} />

                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <TextField
                                    label=' Last Name'
                                    required={true}
                                    value={lastName}
                                    disabled={viewOnly}
                                    onChange={(e) => setLastName(e.target.value)}
                                    placeholder=' Last Name' />
                                <SelectField
                                    // className={`${facility === "0" && 'border-red-500 !important'}`}
                                    // onChange={(e) => setFacility(e.target.value)}
                                    label='Select Gender'
                                    placeHolder={true}
                                    value={gender}
                                    disabled={viewOnly}
                                    onChange={(e) => setGender(e.target.value)}
                                    options={[{ name: 'Select Gender', id: '' }, { name: 'Male', id: '1' }, { name: 'Female', id: '2' }, { name: 'Other', id: '3' }]}
                                />
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <MultiSelectField
                                    // className={`${facility === "0" && 'text-[var(--color-9E9E9E)] !important'}`}
                                    onChange={(values) => setFacility(values)}
                                    value={facility}
                                    disabled={viewOnly}
                                    placeHolder={'Select Facility'}
                                    required={true}
                                    label='Select Facility'
                                    selectAllLabel={{ name: 'All Facility', id: 'All', isChecked: false }}
                                    options={facilityData}
                                />
                                <div className="w-full"> &nbsp;</div>
                            </div>
                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="w-full flex flex-col gap-1">
                                    <TextField
                                        type='email'
                                        label='Email'
                                        value={email}
                                        disabled={viewOnly}
                                        onChange={e => setEmail(e.target.value)}
                                        required={true}
                                        placeholder='Email' />
                                    {(!addProvider && !resendEmail && hasResendEmailPermission) && <div className='w-full text-right'>
                                        <sup
                                            className="cursor-pointer text-[var(--color-0048D4)] hover:underline"
                                            onClick={() => onResendEmail({ user_id: userEmailId, email: email })}
                                        >
                                            Resend email
                                        </sup>
                                    </div>}
                                </div>
                                <PhoneInputFeild
                                    inputProps={{
                                        name: `mobile`,
                                        autoFocus: false,
                                        disabled: viewOnly,
                                    }}
                                    value={mobile}
                                    defaultCountry={DEFAULT_COUNTRY_US}
                                    label='Mobile'
                                    placeholder='Mobile Number'
                                    onChange={phone => setMobile(phone)} />
                            </div>

                            <div className="flex gap-8 w-full mt-9 flex-wrap md:flex-nowrap">
                                <div className="w-full">
                                    <div className="flex items-center justify-between mb-2">
                                        <span className=' text-[15px] text-[var(--color-212121)] tracking-[0.05px] w-[141px]'>
                                            Email Notifications
                                        </span>
                                        <div className="flex gap-4">
                                            <input type="radio" id="Yes" name="email_notification" value="1" disabled={viewOnly} checked={emailNotification === '1'} onClick={() => setEmailNotification('1')} /><label htmlFor="Yes">Yes</label></div>
                                        <div className="flex gap-4">
                                            <input type="radio" id="No" name="email_notification" value="2" disabled={viewOnly} checked={emailNotification === '2'} onClick={() => setEmailNotification('2')} /><label htmlFor="No">No</label></div>

                                    </div>

                                </div>
                                <div className="w-full"> &nbsp;</div>

                            </div>
                            <div className="flex gap-8 w-full my-9 flex-wrap md:flex-nowrap">

                                <div className="w-full">
                                    <span className="text-[15px] text-[var(--color-212121)] tracking-[0.05px]">
                                        {loginUserRole === USER_ROLE_PROVIDER ? <>Signature (Sign or Upload Image)</> : <>Signature (Upload Image)</>}
                                    </span>
                                    <div className="flex mt-[18px] flex-col gap-1">
                                        <div className="flex justify-center items-center border border-[var(--color-9E9E9E)] rounded-xl h-[175px]  overflow-hidden object-fit">

                                            {(signImage !== '' || viewOnly) ? <img src={signImage} alt="" className='h-[175px] w-auto min-h-[100px]' /> : loginUserRole === USER_ROLE_PROVIDER ? <SignatureCanvas
                                                canvasProps={{ className: 'sigCanvas w-full h-full ' }}
                                                ref={(data: any) => setSign(data)}
                                            /> : <></>}
                                        </div>
                                        {!viewOnly && <div className="flex justify-between items-center">
                                            <label htmlFor='signImage' className="relative text-[var(--color-424242)] text-xs tracking-[0.05px] w-fit font-medium cursor-pointer">
                                                Upload Sign Image
                                                <input
                                                    name='signImage'
                                                    id='signImage'
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg"
                                                    className="hidden"
                                                    onChange={(e) => handleAddSign(e)}
                                                />
                                            </label>
                                            <span className='text-right text-[var(--color-424242)] text-xs tracking-[0.05px] font-medium cursor-pointer ' onClick={() => {
                                                handleRemoveSign();
                                            }}>Clear</span></div>}
                                    </div>
                                </div>
                                <div className='w-full'></div>
                            </div>
                            {!viewOnly && <>
                                <hr className='border-[var(--color-E0E0E0)] ' />
                                <div className="flex gap-4 justify-end items-end my-9">
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Cancel'
                                        onClick={() => handleProviderReset()}
                                        className='px-12 !important bg-[var(--color-white)] !important imp-text-424242  hover:bg-[var(--color-0000000a)]' />
                                    <ButtonType
                                        type='webCardbtn'
                                        buttonLabel='Save'
                                        buttonType='submit'
                                        className='px-12 bg-[var(--color-0048D4)]' />
                                </div></>}
                        </div>
                    </form>
                </Box>
            </Modal>
            <ConfirmationMessage
                title={message.CFPCareConnect}
                description={`Are you sure you want to ${storeStatus ? 'deactivate' : 'activate'} this Provider?`}
                open={showStatusConfirmation}
                textType={false}
                type={storeStatus ? 'danger' : 'success'}
                buttonTwoOnClick={() => setShowStatusConfirmation(false)}
                buttonOneOnClick={() => handleConfiramation()}
                buttonLabelOne={storeStatus ? 'Deactivate' : 'Activate'}
                buttonLabelTwo='Cancel'
            />
        </div>
    )
}

export default ProviderList
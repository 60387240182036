import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { IAutoReplyListState } from "../../models/autoReplyInterfaces";
import {
  addHoliday,
  deleteHolidayData,
  fetchAutoReplyList,
  updateHolidayData,
} from "../../services/autoReplyServices";
import { setNotification } from "../../components/notification/ToastNotification";
import { message } from "../../utilities/locals/messages";
import { DATE_ALREADY_EXIST } from "../../config/permissions";

export interface AutoReplyState {
  loading: "idle" | "loading" | "success" | "fetchSuccessfully" | "error";
  autoReplyListData: IAutoReplyListState[];
}

const initialState: AutoReplyState = {
  loading: "idle",
  autoReplyListData: [],
};

export const autoReplyListSlice = createSlice({
  name: "autoReplyListSlice",
  initialState,
  reducers: {
    changeLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAutoReplyList.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(fetchAutoReplyList.fulfilled, (state, action) => {
      state.autoReplyListData = action.payload as IAutoReplyListState[];

      state.loading = "fetchSuccessfully";
    });
    builder.addCase(fetchAutoReplyList.rejected, (state) => {
      state.loading = "error";
    });

    builder.addCase(addHoliday.pending, (state) => {
      state.loading = "idle";
    });
    builder.addCase(addHoliday.fulfilled, (state) => {
      state.loading = "success";
      setNotification(
        message.CFPCareConnect,
        message.holidayAddedSuccessfully,
        false,
        "success",
        "autoReplyListSlice"
      );
    });
    builder.addCase(addHoliday.rejected, (state, { payload }: any) => {
      state.loading = "error";
      
      if (
        payload.response.data.message ===  DATE_ALREADY_EXIST
      ) {
        setNotification(
          message.CFPCareConnect,
          message.holidayAlreadyExist,
          false,
          "failed",
          "autoReplyListSlice"
        );
      } else {
        setNotification(
          message.CFPCareConnect,
          message.holidayAddedfailed,
          false,
          "failed",
          "autoReplyListSlice"
        );
      }
    });

    builder.addCase(updateHolidayData.pending, (state) => {
      state.loading = "idle";
    });
    builder.addCase(updateHolidayData.fulfilled, (state) => {
      state.loading = "success";
      setNotification(
        message.CFPCareConnect,
        message.holidayUpdatedSuccessfully,
        false,
        "success",
        "autoReplyListSlice"
      );
    });
    builder.addCase(updateHolidayData.rejected, (state) => {
      state.loading = "error";
      setNotification(
        message.CFPCareConnect,
        message.holidayUpdatedfailed,
        false,
        "failed",
        "autoReplyListSlice"
      );
    });

    
    builder.addCase(deleteHolidayData.pending, (state) => {
      state.loading = "loading";
    });
    builder.addCase(deleteHolidayData.fulfilled, (state) => {

      state.loading = "success";
      setNotification(
        message.CFPCareConnect,
        message.holidayDeletedSuccessfully,
        false,
        "success",
        "autoReplyListSlice"
      );
    });
    builder.addCase(deleteHolidayData.rejected, (state,action: PayloadAction<any>) => {
      state.loading = "error";

      if (action.payload.response.data.message.length > 0) {
        setNotification(
          message.CFPCareConnect,
          action.payload.response.data.message,
          false,
          "failed",
          "autoReplyListSlice"
        );
        
      }else{
      setNotification(
        message.CFPCareConnect,
        message.holidayDeletedfailed,
        false,
        "failed",
        "autoReplyListSlice"
      );}
    });

  },
});

// Action creators are generated for each case reducer function
export const { changeLoading } = autoReplyListSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const AutoReplyListSlices = (state: RootState) =>
  state.autoReplyListData;

export const autoReplyListReducer = autoReplyListSlice.reducer;

export const USER_TYPE_PATIENT_ID           = '1';    
export const USER_TYPE_PATIENT_LABEL        = 'Patient';
export const USER_TYPE_RELATIVE_ID          = '2';
export const USER_TYPE_RELATIVE_LABEL       = 'Relative';

export const USER_TYPE_SEL_OPTIONS = [
    { id: USER_TYPE_PATIENT_ID,  name: USER_TYPE_PATIENT_LABEL }, 
    { id: USER_TYPE_RELATIVE_ID,  name: USER_TYPE_RELATIVE_LABEL }, 
]; 

export const GENDER_MALE_ID             = '1';   // USED IN PATIENT PROFILE 
export const GENDER_MALE_LABEL          = 'Male';
export const GENDER_FEMALE_ID           = '2';
export const GENDER_FEMALE_LABEL        = 'Female';
export const GENDER_OTHER_ID            = '3';
export const GENDER_OTHER_LABEL         = 'Other';

export const GENDER_SEL_OPTIONS = [
    { id: '', name: 'Select Gender' },
    { id: GENDER_MALE_ID,  name: GENDER_MALE_LABEL }, 
    { id: GENDER_FEMALE_ID,  name: GENDER_FEMALE_LABEL }, 
    { id: GENDER_OTHER_ID,  name: GENDER_OTHER_LABEL }
]; 

export const EMAIL_NOTIFICATIONS_YES    = '1'; // USED IN PATIENT PROFILE 
export const EMAIL_NOTIFICATIONS_NO     = '2';


export const PATIENT_TYPE_SELF ='1';

export const ACTION_MENU_APPROVE = 'approve';
export const ACTION_MENU_VIEW  = 'view';
export const ACTION_MENU_EDIT  = 'edit';
export const ACTION_MENU_DEACTIVATE  = 'deactivate';
export const ACTION_MENU_DELETE  = 'delete';
export const ACTION_MENU_SAVE  = 'save';

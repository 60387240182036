const version = 'api/1.0/'

export const URL_VERSION = version + 'version-list/';

export const URL_REFRESH = version + 'user/token/refresh';
export const URL_LOGIN = version + 'login/';
export const URL_LOGIN_PATIENT = version + 'patient-login/';
export const URL_LOGIN_USER_PATIENT = version + 'patient-user-login/';
export const URL_LOGIN_OTP_VERIFICATION = version + 'otp-patient-login/';
export const URL_LOGOUT = version + 'logout/';
export const URL_PWD_RESET_SUBMIT = version + 'user/reset-password/';
export const URL_FORGET_PWD = 'forgot-password/';
export const URL_UPLOAD_IMAGE = version + 'user/upload-pics/';
export const URL_REMOVE_IMAGE = version + 'user/remove-pics/';

export const URL_RESENT_MAIL = version + 'user/resend-email/';

export const URL_CLIENT_LIST = version + 'user/clients/';
export const URL_ADD_CLIENT = version + 'user/clients/';
export const URL_EDIT_CLIENT = version + 'user/clients/';
export const URL_UPDATE_CLIENT = version + 'user/clients/';
export const URL_STATUS_CLIENT = version + 'user/deactivate-client/';

export const URL_FACILITY_LIST = version + 'user/facility/';
export const URL_ADD_FACILITY = version + 'user/facility/';
export const URL_EDIT_FACILITY = version + 'user/facility/';
export const URL_UPDATE_FACILITY = version + 'user/facility/';
export const URL_STATUS_FACILITY = version + 'user/deactivate-facility/';
export const URL_FACILITY = version + 'user/facility-list/';

export const URL_ASSOCIATE_LIST = version + 'user/list-user/';
export const URL_ADD_ASSOCIATE = version + 'user/associate-user/';
export const URL_EDIT_ASSOCIATE = version + 'user/associate-user/';
export const URL_UPDATE_ASSOCIATE = version + 'user/associate-user/';
export const URL_STATUS_ASSOCIATE = version + 'user/deactivate-user/';

export const URL_PROVIDER_LIST = version + 'user/list-user/';
export const URL_ADD_PROVIDER = version + 'user/provider/';
export const URL_EDIT_PROVIDER = version + 'user/provider/';
export const URL_UPDATE_PROVIDER = version + 'user/provider/';
export const URL_STATUS_PROVIDER = version + 'user/deactivate-user/';

export const URL_NURSING_LIST = version + 'user/list-user/';
export const URL_ADD_NURSING = version + 'user/nursing-staff/';
export const URL_EDIT_NURSING = version + 'user/nursing-staff/';
export const URL_UPDATE_NURSING = version + 'user/nursing-staff/';
export const URL_STATUS_NURSING = version + 'user/deactivate-user/';
export const URL_APPROVE_NURSING = version + 'user/approve-user/';

export const URL_PATIENT_LIST = version + 'user/list-user/';
export const URL_ADD_PATIENT = version + 'user/patients/';
export const URL_EDIT_PATIENT = version + 'user/patients/';
export const URL_UPDATE_PATIENT = version + 'user/patients/';
export const URL_STATUS_PATIENT = version + 'user/deactivate-user/';
export const URL_APPROVE_PATIENT = version + 'user/approve-user/';
export const URL_PATIENT_FAMILY_MEMBER = version + 'user/relatives-list/';

export const URL_MESS_INBOX = version + 'message/view-all-message/';
export const URL_MESS_COMPLETED = version + 'message/list-complete-messages/';
export const URL_MESS_SENT = version + 'message/user-sent_msgs/';
export const URL_MARK_COMPLETED = version + 'message/complete-messages/';
export const URL_MESS_SINGLE = version + 'message/user-msg-history/';
export const URL_MESS_MARK_NOTIFICATION = version + 'user/make-user-notifications-read/';
export const URL_MESS_HISTORY_PATIENT = version + 'message/patient-user-msg-history/'; 

export const URL_DASHBOARD_INDIV = version + 'message/list-msg-users/';
export const URL_ADD_MESSAGE = version + 'message/add-message/';
export const URL_PLACE_ORDER = version + 'user/get-client-data/';


export const URL_STATE = version + 'common/state/';
export const URL_RELATIONSHIP = version + 'user/relationship/';

export const URL_PATIENT_ALL_FACILITIES = version + 'list-all-facility/';
export const URL_PATIENT_RELATION = version + 'relationship/';

export const URL_PATIENT_REG_UPLOAD_IMG = version + 'upload-pics/';
export const URL_PATIENT_REG_REMOVE_IMG = version + 'remove-pics/';
export const URL_PATIENT_REG = version + 'patients-reg/';

export const URL_FAMILY_REG_UPLOAD_IMG = version + 'upload-pics/';
export const URL_FAMILY_REG_REMOVE_IMG = version + 'remove-pics/';
// export const URL_FAMILY_REG = version + 'patients-reg/';
export const URL_FAMILY_REG = version + 'relative-reg/';

export const URL_NURSING_REG_UPLOAD_IMG = version + 'upload-pics/';
export const URL_NURSING_REG_REMOVE_IMG = version + 'remove-pics/';
export const URL_NURSING_REG = version + 'nursing-staff-reg/';

export const URL_LIST_ROLES = version + 'user/list-roles/';
export const URL_CHANGE_ROLES = version + 'user/change-permissions/';
export const URL_SINGLE_ROLES = version + 'user/roles-permissions/';


export const URL_FIREBASE_TOKEN = version + 'user/save-fcm-token/';
export const URL_FETCH_NOTIFICATION = version + 'user/fetch-notifications/';
export const URL_MARK_ALL_NOTIFICATION_READ = version + 'user/make-all-notifications-read/';

export const URL_TIMESHEET_PROVIDER = version + 'reports/list-provider/';
export const URL_TIMESHEET_LIST = version + 'reports/generate-timesheet/';
export const URL_EXPORT_TIMESHEET = version + 'reports/export-timesheet/';
export const URL_TIMESHEET_PATIENT = version + 'reports/list-patient/';

export const URL_PATIENT_SEARCH = version + 'reports/list-patient/';


export const URL_FETCH_PROFILE_PIC = version + 'message/sender-profile-pic/';

export const URL_FETCH_AUTO_REPLY = version + 'common/holidays/';
export const URL_ADD_HOLIDAY    = version + 'common/holidays/';
export const URL_UPDATE_HOLIDAY    = version + 'common/holidays/';
export const URL_DELETE_HOLIDAY    = version + 'common/holidays/';

export const URL_FETCH_MESSAGE_TEMPLATE      = version + 'common/message-templates/';
export const URL_ADD_MESSAGE_TEMPLATE        = version + 'common/message-templates/';
export const URL_UPDATE_MESSAGE_TEMPLATE     = version + 'common/message-templates/';
export const URL_DELETE_MESSAGE_TEMPLATE     = version + 'common/message-templates/';




